/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { ApiBlocker, api, changeShowErrorToast, logout } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

// interface for product
interface Product {
  created_by?: number;
  modified_by?: number;
  item_code?: string | number;
  batch_code?: string | number;
  pts?: number;
  ptr?: number;
  mrp?: number;
  remarks?: string | null;
  company_productprice_id?: number;
  active_status?: "Y" | "N";
  isDefault?: boolean;
  created_date?: string;
  expiry_date?: string;
  modified_date?: string;
  company_divisioncode?: string | number;
  companyDivisionCode?: string | number;
  event_type?: string | number;
  u_code: string;
}

interface ApiResponse {
  success: boolean;
  message: string;
  data?: any;
  status?: number;
  // Add other properties as needed
}

let instance: AxiosInstance;
let eventInstance: AxiosInstance;
let instance2: AxiosInstance;

const axiosInstance = async () => {
  instance = axios.create({
    baseURL: api + "/api/v1/product",
  });

  instance2 = axios.create({
    baseURL: api + "/api/v1/state",
  });

  // Add the interceptor to the instance
  instance.interceptors.response.use(
    (success: any) => {
      let productString = "";
      if (success.config.baseURL.includes("product") && success.data && window.sessionStorage.getItem("detailsId")) {
        productString = `product_${window.sessionStorage.getItem("detailsId")}`;
        productString && ApiBlocker.eventChecked(productString);
      }
      return success;
    },
    (error) => {
      if (error?.response?.status === 403) {
        // logout
        logout();
      }
    }
  );

  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const params = config.url?.split("?")?.[1];

      if (params && (params.includes("undefined") || params?.includes("null"))) {
        return Promise.reject(new Error("Request cancelled due to undefined parameters."));
      }

      return addAuthHeader(config);
    },
    (error) => {
      if (error?.response?.status === 403) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  instance2.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      return addAuthHeader(config);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance2.interceptors.response.use(
    (success: any) => {
      return success;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// axios event instance
const axiosEventInstance = async () => {
  eventInstance = axios.create({
    baseURL: api + "/event/product",
  });

  // Add the interceptor to the instance
  eventInstance.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 403) {
      // logout
      logout();
    }

    eventInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
  });

  eventInstance.interceptors.request.use(
    (config) => {
      if (
        (ApiBlocker.isEventBlocked || ApiBlocker.isEventPending) &&
        config.url !== "/add" &&
        window.location.pathname.toLowerCase().includes("/details")
      ) {
        const errorMessage = ApiBlocker.isEventBlocked
          ? "Request cancelled due to event blocked status"
          : "Request cancelled due to event pending status";
        changeShowErrorToast(true, errorMessage);
        return Promise.reject(errorMessage);
      }

      // // triming values before sending to backend
      // if ((config.method === "post" || config.method === "put" || config.method === "patch") && config?.data?.data) {
      //   Object.keys(config.data.data).forEach((key) => {
      //     if (typeof config.data.data[key] === "string") {
      //       config.data.data[key] = config.data.data[key]?.trim();
      //     }
      //     if (typeof config.data.data[key] === "number") {
      //       // convert to string first then trim
      //       config.data.data[key] = config.data.data[key]?.toString()?.trim();
      //       config.data.data[key] = Number(config.data.data[key]);
      //     }
      //   });
      // }

      // if (config.url !== "/add") ApiBlocker.eventChecked();

      return addAuthHeader(config);
    },
    (error) => {
      console.log("after wards", error);
      if (error?.response?.status === 403) {
        logout();
      }
      return Promise.reject(error);
    }
  );
};

axiosEventInstance();

axiosInstance();

// const instance: AxiosInstance = axios.create({
//   baseURL: api + "/api/v1/product",

//   headers: {
//     "Content-Type": "application/json",
//     Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
//   },
// });

// //product add edit
// const eventInstance: AxiosInstance = axios.create({
//   baseURL: api + "/event/product",

//   headers: {
//     "Content-Type": "application/json",
//     Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
//   },
// });

// Function to generate unique ID with timestamp and a unique identifier
const generateUniqueID = (): string => {
  // Get the current timestamp in milliseconds
  let timestamp = Date.now();

  // Generate a unique identifier
  let uniqueIdentifier = Math.random().toString(36).substr(2);

  // Combine the timestamp and unique identifier to create a unique ID
  let uniqueID = timestamp.toString() + uniqueIdentifier;

  // Return the unique ID
  return uniqueID;
};

// The response will look like: "1617634028536jzhsd7k"

export const getAllProductDetails = async (): Promise<ApiResponse> => {
  return instance.get("/details");
};

export const getSearchRegionDetails = async (query: string): Promise<ApiResponse> => {
  return instance.get(query);
};

export const queryProductDetails = async (query: string): Promise<AxiosResponse> => {
  if (query?.toString()?.toLowerCase()?.includes("null")) return Promise.reject(new Error("Request cancelled due to null query."));
  if (query?.toString()?.toLowerCase()?.includes("undefined")) return Promise.reject(new Error("Request cancelled due to undefined query."));
  return instance.get(query);
};

export const getAllCityDetails = async (query?: string): Promise<AxiosResponse> => {
  if (query) return instance.get("/city?search=" + query);
  return instance.get("/city?search=");
};

export const getAllDistrictDetails = async (query?: string): Promise<AxiosResponse> => {
  if (query) return instance.get("/district?search=" + query);
  return instance.get("/district?search=");
};

export const getAllStateDetails = async (query?: string): Promise<AxiosResponse> => {
  if (query) return instance.get("/state?search=" + query);
  return instance.get("/state?search=");
};

export const getAllStatusDetails = async (query?: string): Promise<AxiosResponse> => {
  if (query) return instance.get("/active_status?search=" + query);
  return instance.get("/active_status?search=");
};

export const StateDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};
export const CityDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};
export const DistrictDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};
export const PsrDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get(query);
};

export const getProductPrice = async (item_code: string): Promise<ApiResponse> => {
  return instance.get(`/get-product-price?company_productcode=${item_code}`);
};

export const getVisibleInvisibleData = async (item_code: string, company_divisioncode: string): Promise<ApiResponse> => {
  console.log("item_code", item_code);
  return instance.get(`/region/visiblity?item_code=${item_code}&company_divisioncode=${company_divisioncode}`);
};

export const getPSRVisibleInvisibleData = async (item_code: string, company_divisioncode: string): Promise<ApiResponse> => {
  console.log("item_code", item_code);
  return instance.get(`/psr?item_code=${item_code}&company_divisioncode=${company_divisioncode}`);
};

export const getSimilarProductWithDivisionCode = async (data: number[]): Promise<ApiResponse> => {
  return instance.get(`details/division?companydivision_code=${data}`);
};

export const getUniqueItemCode = async (item_code: string | number, company_code: number | string): Promise<ApiResponse> => {
  return instance.get(`/itemcode/unique?item_code=${item_code}&company_code=${company_code}`);
};

export const isProductPriceUnique = async ({
  mrp,
  ptr,
  pts,
  productCode,
}: {
  mrp: string | number;
  ptr: string | number;
  pts: string | number;
  productCode: string | number;
}) => instance.get(`/price/already-added?company_productcode=${productCode}&pts=${pts}&ptr=${ptr}&mrp=${mrp}`);

export const patchRegionVisibility = async (query: string, data: object[] | object | any, event_type: any): Promise<ApiResponse> => {
  return eventInstance.post(query, {
    data: { ...data, u_code: generateUniqueID() },
    event_type: event_type,
    primary_key: data?.item_code,
  });
};

export const updateStateDetails = async (query: string, data: object[] | object): Promise<AxiosResponse> => {
  return instance.patch(query, data);
};
export const updatePsrDetails = async (query: string, data: object[] | object): Promise<AxiosResponse> => {
  return instance.patch(query, data);
};

export const addProduct = async (data: any): Promise<ApiResponse> => {
  // data.created_by = 0;
  // data.modified_by = 0;
  return eventInstance.post("/add", {
    data: { ...data, u_code: generateUniqueID() },
    event_type: "PRODUCT_ADD",
  });
};

export const editProduct = async (data: any, primary_key: number | string | null | undefined, eventType?: string): Promise<ApiResponse> => {
  // data.created_by = 0;
  // data.modified_by = 0;
  if (!primary_key) throw new Error();
  return eventInstance.put("/edit", {
    data: { ...data, company_productcode: primary_key },
    event_type: eventType || "PRODUCT_EDIT",
    primary_key: primary_key,
  });
};

export const EditProductPrice = async (data: Product, primary_key: number): Promise<ApiResponse> => {
  // return instance.post(`price/edit`, data);

  return eventInstance.post("price/edit", {
    data: { ...data, company_productcode: primary_key },
    event_type: "PRODUCT_PRICE_EDIT",
    primary_key: primary_key,
  });
};

export const addProductPrice = async (data: Product, primary_key: number): Promise<ApiResponse> => {
  // return instance.post(`price/add`, data);

  return eventInstance.post("price/add", {
    data: { ...data, company_productcode: primary_key, u_code: generateUniqueID() },
    event_type: "PRODUCT_PRICE_ADD",
    primary_key: primary_key,
  });
};

export const DeleteRegion = async (query: string, data: Product, pk: any, eventType?: string): Promise<ApiResponse> => {
  return eventInstance.delete(query, {
    data: {
      data: data,
      event_type: eventType || "PRODUCT_VISIBLITY_REGION_DELETE",
      primary_key: pk,
    },
  });
};

export const poGeneration = async (data: any): Promise<ApiResponse> => {
  return instance.post("po-generation", data);
};

export const deactivateProduct = async (data: any): Promise<ApiResponse> => {
  return eventInstance.delete("/delete", {
    data: {
      data: data,
      event_type: "PRODUCT_DELETE",
      primary_key: data?.company_productcode,
    },
  });
};

export const replaceProductApi = async (data: any): Promise<ApiResponse> => {
  return eventInstance.post("replace", {
    data: data,
    event_type: "PRODUCT_REPLACE_ADD",
    primary_key: data?.old_item_code,
  });
};

export const counterType = async (query?: string): Promise<ApiResponse> => {
  if (query) return instance.get("/get_counterTypes" + query);
  return instance.get("/get_counterTypes");
};

export const getProductMasterStateCityDistrictAutocomplete = async (
  type: string,
  item_code: string,
  company_divisioncode: string,
  page?: number,
  perPage?: number,
  value?: string
): Promise<ApiResponse> => {
  if (!page && !perPage && type != "state") return instance.get(`/${type}?item_code=${item_code}&company_divisioncode=${company_divisioncode}`);

  return instance.get(`/${type}`, {
    params: {
      perPage,
      page,
      search: value,
    },
  });
};

export const getStateCityAndDistrictDetails = async (city_codes: number[], state_codes: number[], district_codes: number[]): Promise<ApiResponse> => {
  return instance2.get(`region-data?city_codes=${city_codes}&state_codes=${state_codes}&district_codes=${district_codes}`);
};
