import { X } from "lucide-react";
import errorIcon from "../assets/cancelCircle.svg";
import pendingCircle from "../assets/pendingCircle.svg";
import successIcon from "../assets/successCircle.svg";

import moment from "moment";
import { useState } from "react";
import Badge from "../components/Badge.tsx";
import { useOutsideClick } from "../hooks/useOutsideClick.tsx";
import { Dot } from "./Dot";

interface TimeLineChipProps {
  title: string;
  name: string;
  location: string;
  dateTime: string;
  status?: string;
  preq_status?: string;
}

export function TimeLineChip({ title, name, location, dateTime, status, preq_status }: TimeLineChipProps) {
  const [addedTags, setAddedTags] = useState([]);
  const [tagInput, setTagInput] = useState("");

  const [enableTagAdding, setEnableTagAdding] = useState(false);

  const inputTagRef: any = useOutsideClick(() => {
    setAddedTags((prev: any) => {
      let updatedTags = structuredClone(prev);
      if (updatedTags?.length === 0) {
        updatedTags = [];
      }
      if (tagInput) updatedTags.push(tagInput);
      setTagInput("");
      return updatedTags;
    });
    setEnableTagAdding(false);
  });

  return (
    <>
      <div className="flex items-center gap-4 my-5 font-semibold">
        {status === "reject" && <img src={errorIcon} alt="" />}
        {(status?.toLowerCase() === "approval" ||
          status === "post" ||
          status === "accept" ||
          status === "approve" ||
          status === "validate" ||
          status?.toString()?.toLowerCase() === "complete" ||
          status?.toString()?.toLowerCase() === "completed") && <img src={successIcon} alt="" />}
        {status === "pending" && <img src={pendingCircle} alt="" />}
        {!status && preq_status === "complete" && <img src={successIcon} alt="" />}

        <h2>
          {title} Action <span>-</span> {status === "reject" && <span className="text-[#EB3D3D]">Rejected</span>}{" "}
          {status === "pending" && <span className="text-[#EB3D3D]">Pending</span>}{" "}
          {(status === "approval" || status === "approve") && <span className="text-[#3a6f1f]">Approved</span>}
          {status === "post" && <span className="text-[#3a6f1f]">Posted</span>}
          {(status === "accept" ||
            status === "validate" ||
            status?.toString()?.toLowerCase() === "complete" ||
            status?.toString()?.toLowerCase() === "completed") && <span className="text-[#3a6f1f]">Accepted</span>}
          {!status && preq_status === "complete" && <span className="text-[#3a6f1f]">Completed</span>}
        </h2>
      </div>

      <div className="h-auto border-gray-600 border-s ps-6 ms-2">
        <div className="flex items-center gap-3 mb-2 text-sm font-normal text-gray-500 dark:text-gray-400">
          <p>{name}</p>
          <Dot />
          {location}
        </div>

        {dateTime && status && status !== "pending" && (
          <time className="flex items-center gap-3 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            {moment(dateTime).format("MMMM Do YYYY")} <Dot /> {moment(dateTime).format("h:mm a")}
          </time>
        )}

        <div className="flex flex-col w-20 gap-2 my-2">{addedTags?.map((tag: any) => <Badge key={tag}>{tag}</Badge>)}</div>
        {enableTagAdding && (
          <div className="flex items-center w-20 bg-purple-100 border-2 border-purple-500 rounded-lg ">
            <input
              placeholder="Tag"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              ref={inputTagRef}
              className="w-11/12 bg-transparent focus:outline-0 px-2 py-0.5 rounded-lg text-sm "
            />
            <X className="cursor-pointer" size={18} color="black" onClickCapture={() => setEnableTagAdding(false)} />
          </div>
        )}
      </div>
    </>
  );
}
