export const rolesToRestrict = ["Company Admin", "Company Admin - Division", "Medvol Field"];

export const adminList = [
  "Company Admin",
  "Company Admin - Division",
  "Management",
  "Sr Management - HO",
  "Account Managers",
  "Master",
  "Master Module",
];
