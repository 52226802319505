import { Button } from "src/components";
import { useState } from "react";
import downArrow from "src/assets/down-arrow.svg";
import { Tab } from "@headlessui/react";
import classNames from "classnames";

const category = ["Invoice", "PO Generation", "CN Advice", "Replenishment"];

const invoices = [
  {
    id: 1233,
    date: "1 April, 2024",
    suppliedQty: "20 + 4 @35%",
    batchNo: "X1246546",
    mrp: "₹234.50",
    ptr: "₹234.50",
    billedPrice: "₹240.50",
    cashDiscount: "3%",
  },
  {
    id: 1234,
    date: "1 April, 2024",
    suppliedQty: "20 + 4 @35%",
    batchNo: "X1246546",
    mrp: "₹234.50",
    ptr: "₹234.50",
    billedPrice: "₹240.50",
    cashDiscount: "3%",
  },
];

const ORD2 = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className="max-w-[1400px] mt-6 w-full mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white">
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="flex gap-6 items-center">
              <p className="font-semibold text-[1.2rem]">Xanlox V1 Capsule 10s</p>
              <p className=" text-[.9rem]">Order Qty. 60 + 12</p>
              <p className="text-[.9rem] font-semibold border border-[#E4E4E6] rounded-lg px-2 py-1">CD: 3%</p>
            </div>
            <p className="font-semibold flex items-center w-fit mt-2 text-[.8rem] h-6 px-2 rounded-full bg-[#cdfee1] text-[#0C5132]">
              Order Replenished
            </p>
          </div>
          <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
            <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
          </Button>
        </div>
        <div
          className={`overflow-hidden mt-6 transition-all duration-500 ease-in-out ${
            isExpanded ? "opacity-100 max-h-[1000px]" : "max-h-0 opacity-0"
          }`}
          style={{ transitionProperty: "max-height, opacity" }}
        >
          <div className="border-t border-[#d0d0d0]"></div>

          <Tab.Group>
            <div className="flex justify-between mt-6">
              <Tab.List className="flex p-[2px] space-x-1 rounded-lg bg-[#e3e3e3] w-fit h-[38px] items-center">
                {category.map((category) => (
                  <Tab
                    key={category}
                    className={({ selected }) =>
                      classNames(
                        "w-fit px-4 rounded-md py-1.5 text-sm font-normal leading-5",
                        "focus:outline-none ",
                        selected ? "bg-white border border-[#d0d0d0] shadow-sm" : "hover:bg-white/[0.12] hover:text-black"
                      )
                    }
                  >
                    {category}
                  </Tab>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels className="mt-2">
              <Tab.Panel className={classNames("w-full bg-white rounded-xl", "ring-offset-2 ring-white/60 focus:outline-none focus:ring-2")}>
                {" "}
                <div className="mt-6">
                  {invoices.map((invoice) => (
                    <div key={invoice.id}>
                      <h3 className="text-[.9rem]">Invoice #{invoice.id}</h3>
                      <table className="mt-2 min-w-full border">
                        <thead className="w-full">
                          <tr>
                            <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">Invoice Date</th>
                            <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">
                              Supplied Qty. @Scheme
                            </th>
                            <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">Batch No.</th>
                            <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">MRP</th>
                            <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">PTR</th>
                            <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">Billed Price</th>
                            <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">Cash Discount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-sm">
                            <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{invoice.date}</td>
                            <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{invoice.suppliedQty}</td>
                            <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{invoice.batchNo}</td>
                            <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{invoice.mrp}</td>
                            <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{invoice.ptr}</td>
                            <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{invoice.billedPrice}</td>
                            <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{invoice.cashDiscount}</td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                    </div>
                  ))}
                </div>
              </Tab.Panel>
              <Tab.Panel className={classNames("bg-white rounded-xl", "ring-offset-2 ring-white/60 focus:outline-none focus:ring-2")}></Tab.Panel>
              <Tab.Panel className={classNames("bg-white rounded-xl", "ring-offset-2 ring-white/60 focus:outline-none focus:ring-2")}></Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          <div></div>
        </div>
      </div>
      <div className="max-w-[1400px] mt-10 w-full mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white">
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="flex gap-6 items-center">
              <p className="font-semibold text-[1.2rem]">Xanlox V1 Capsule 10s</p>
              <p className=" text-[.9rem]">Order Qty. 60 + 12</p>
              <p className="text-[.9rem] font-semibold border border-[#E4E4E6] rounded-lg px-2 py-1">CD: 3%</p>
            </div>
            <p className="font-semibold flex items-center w-fit mt-2 text-[.8rem] h-6 px-2 rounded-full bg-[#cdfee1] text-[#0C5132]">
              Order Replenished
            </p>
          </div>
          <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
            <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
          </Button>
        </div>
        <div
          className={`overflow-hidden mt-6 transition-all duration-500 ease-in-out ${
            isExpanded ? "opacity-100 max-h-[1000px]" : "max-h-0 opacity-0"
          }`}
          style={{ transitionProperty: "max-height, opacity" }}
        ></div>
      </div>
    </div>
  );
};

export default ORD2;
