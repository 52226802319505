import { forwardRef, Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, Spinner } from "src/components";
import { TimeLineChip } from "../../components/TimeLineChip";
import { ordActions } from "src/context/order-slice";
import { RootState } from "src/context/store";
import { getOrderApprovalHierarchy } from "src/services/order-api";

interface OrderHierarchySidebarProps {
  onClose: () => void;
  order_id: number;
}

const OrderHierarchySidebar = forwardRef<HTMLElement, OrderHierarchySidebarProps>(({ onClose, order_id }: OrderHierarchySidebarProps, ref) => {
  const { approvalHierarchy } = useSelector((state: RootState) => state.ord);
  const { selectedCounterData } = useSelector((state: RootState) => state.ord);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchApprovalHierarchy = async () => {
      try {
        setIsLoading(true);
        const response = await getOrderApprovalHierarchy(order_id, selectedCounterData?.company_code);
        dispatch(ordActions.setApprovalHierarchy(response?.data?.data?.data));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    dispatch(ordActions.setApprovalHierarchy([]));
    fetchApprovalHierarchy();
  }, [order_id]);

  return (
    <aside
      className="bg-white border-l-[1px] min-w-[25rem] overflow-y-auto max-w-[30rem] border-l-[#00000014] fixed px-4 text-[#49484C] text-[1rem] font-semibold py-5 right-0  h-screen"
      ref={ref}
    >
      <div className="flex justify-between items-center px-6 py-4 border-b border-[#D0D0D0]">
        <h1 className="text-[1.2rem] font-semibold text-gray-800">Approval Hierarchy</h1>
        <Button onClick={onClose} className="">
          Close
        </Button>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <Spinner />
        </div>
      ) : approvalHierarchy?.length && approvalHierarchy?.length > 0 ? (
        <div className="p-4 px-6 overflow-y-auto h-[100vh]">
          {approvalHierarchy?.map((item: any) => {
            if (item?.operation_list?.length === 1 && item?.operation_list?.[0]?.toString()?.toLowerCase() === "viewer") return <></>;
            return (
              <Fragment key={item?.order_status_id}>
                <TimeLineChip
                  title={item?.ff_level}
                  name={item?.actor_name}
                  location={item?.actor_short_code}
                  dateTime={item?.modified_date}
                  status={item?.operation_status}
                  preq_status={item?.operation_status}
                />
              </Fragment>
            );
          })}
        </div>
      ) : (
        <h1 className="text-center text-gray-500 text-lg h-[50vh] flex items-center justify-center">No hierarchy found</h1>
      )}
    </aside>
  );
});

export default OrderHierarchySidebar;
