import { Tab } from "@headlessui/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "src/components/ui/button.component";
import Spinner from "src/components/ui/spinner.component";
import { ordActions } from "src/context/order-slice";
import { RootState } from "src/context/store";
import LocationCard from "src/features/fields/container/LocationCard";
import { getCounterByEmployee, getCounterOrderDetailsById, getOrderIds } from "src/services/order-api";
import cross from "../../assets/cross.svg";
import PurpleLeftArrowIcon from "../../assets/purple-left-arrow.svg";
import OrderDetailsTab from "./OrderDetailsTab";
import { getOrderDetailsCardInfo } from "./OrderHelper";

const tabs = ["Counter Info", "Order Details"];

const OrdersDetailScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedCounterData } = useSelector((state: RootState) => state.ord);

  // const handleLocationClick = (countercompanylnk_id: string) => {
  //   navigate(`/transactions/orders/products/${countercompanylnk_id}`);
  // };

  const fetchDetails = useCallback(async () => {
    setIsLoading(true);
    const counterOrderId = window.sessionStorage.getItem("counterOrderId");

    if (counterOrderId) {
      let res = await getCounterOrderDetailsById(counterOrderId);
      if (res?.status !== 200) throw new Error();
      dispatch(ordActions.setCounterLocationData(res?.data));
    } else {
      navigate("/transactions/orders");
    }
    setIsLoading(false);
  }, []);

  const fetchOrderId = async (counter_company_location_id: number, company_code: string) => {
    const order_id_int = window.sessionStorage.getItem("order_id_int") ? Number(window.sessionStorage.getItem("order_id_int")) : null;
    const res = await getOrderIds(counter_company_location_id, company_code, 1, 10, order_id_int);
    dispatch(ordActions.setOrderDetailsFiltered(res?.data?.data));
  };

  const fetchCounterData = async (counter_company_location_id: number) => {
    const query = `?counter_company_location_id=${counter_company_location_id}`;

    try {
      const response = await getCounterByEmployee(query);
      dispatch(ordActions.setSelectedCounterData(response?.data?.data?.[0]));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(ordActions.setProductsData([]));
    dispatch(ordActions.clearCartDataObj());

    fetchDetails();

    // console.log("company_code", selectedCounterData?.company_code);

    if (!selectedCounterData || Object.keys(selectedCounterData)?.length === 0) {
      fetchCounterData(Number(window.sessionStorage.getItem("counterCompanyLocationId")));
    }
  }, []);

  useEffect(() => {
    if (selectedCounterData && Object.keys(selectedCounterData)?.length > 0) {
      fetchOrderId(selectedCounterData?.counter_company_location_id, selectedCounterData?.company_code);
    }
  }, [selectedCounterData?.company_code]);

  return (
    <div className="h-screen min-h-screen">
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <Spinner />
        </div>
      ) : (
        <div className="h-full bg-white">
          {/* Header Section */}
          {/* <div className="p-6 border-b border-purple-200 bg-gradient-to-r from-purple-50 to-purple-100"> */}
          <div className="mx-32 mt-6">
            <div className="container">
              <Button className="flex items-center px-0 py-2 text-lg text-[#7F56D9]" onClick={() => navigate("/transactions/orders")}>
                <img src={PurpleLeftArrowIcon} alt="back" className="w-3 h-3 mr-3" />
                Back to Counters
              </Button>

              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-[2rem] font-bold text-gray-800">{selectedCounterData?.counter_location?.counter_hdr_id?.counter_name}</h1>
                  <div className="flex items-center gap-2 mt-4">
                    <p className="text-[#5E4200] w-fit px-2 py-1 rounded-xl font-semibold bg-[#FFF1E3] text-[.9rem]">
                      {selectedCounterData?.drph_lnk_type?.dr_ph_lnk_code}
                    </p>
                    <p className="font-semibold text-[.9rem] w-fit px-2 py-1 rounded-xl bg-[#EAF4FF] text-[#00527C]">
                      {selectedCounterData?.counter_location?.counter_hdr_id?.counter_code}
                    </p>
                    <p className="font-semibold flex items-center text-[.9rem] w-fit px-2 py-1 rounded-xl bg-[#E3E3E3] text-[#4A4A4A]">
                      <img src={cross} alt="add-tag" className="w-4 h-4 mr-2 rotate-45 invert" />
                      Add tag
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Tab.Group>
            <Tab.List className="border-b-[1px] px-32 shadow-md border-b-[rgba(0, 0, 0, 0.10)] mb-[1.16rem] mt-4">
              {tabs.map((item: string) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }: { selected: boolean }) => (
                    <div
                      className={`relative inline-block cursor-pointer -bottom-[1px] px-4 py-3 font-semibold text-sm  border-b-2  not-italic  hover:border-b-[#586AF5] hover:text-[#586af5] focus:outline-none ${
                        selected ? "text-[#586af5] border-b-[#586AF5]" : "text-[rgba(0,0,0,0.4)] border-b-transparent"
                      }`}
                    >
                      {item}
                    </div>
                  )}
                </Tab>
              ))}
              <Button
                className="absolute right-32 bg-[#8051FF] text-white"
                onClick={() => {
                  navigate(`/transactions/orders/products/${selectedCounterData?.counter_company_location_id}`);
                  dispatch(ordActions.setSelectedLocation(selectedCounterData));
                }}
              >
                Initiate Order
              </Button>
            </Tab.List>

            <Tab.Panels className="mx-32 mt-8">
              <Tab.Panel>
                {/* Locations Section */}
                <div className="mt-10">
                  <header className=" py-3 border-b border-[#D0D0D0] pb-4">
                    <h3 className="text-xl font-semibold">Counter Details</h3>
                  </header>
                  <div className="mt-2 rounded-bl-lg rounded-br-lg">
                    <LocationCard CardData={getOrderDetailsCardInfo(selectedCounterData)} />
                  </div>
                </div>
                <div className="mt-20">
                  <h2 className="pb-3 mb-6 text-xl font-semibold text-gray-800 border-b border-[#D0D0D0]">Locations</h2>

                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    <div
                      // onClick={() => {
                      //   handleLocationClick(item?.locations?.[0]?.counter_company_location_id);
                      //   dispatch(ordActions.setSelectedLocation(item));
                      // }}
                      className="overflow-hidden transition-all duration-300 ease-in-out transform bg-white border border-gray-200 shadow-md cursor-pointer rounded-2xl hover:shadow-xl hover:-translate-y-1"
                    >
                      <div className="p-6">
                        <div className="flex items-center justify-between pb-4 mb-4 border-b">
                          <h3 className="text-xl font-bold text-gray-800">{selectedCounterData?.company_data?.name}</h3>
                        </div>

                        <div className="grid grid-cols-2 gap-4 mt-8 text-sm">
                          <div className="space-y-4">
                            <div className="flex items-center">
                              <span className="w-32 font-medium text-gray-600">Company Code:</span>
                              <span className="font-semibold text-gray-800">{selectedCounterData?.company_data?.code}</span>
                            </div>
                            <div className="flex items-center">
                              <span className="w-32 font-medium text-gray-600">Link Type:</span>
                              <span className="font-semibold text-gray-800">{selectedCounterData?.drph_lnk_type?.dr_ph_lnk_code}</span>
                            </div>
                          </div>

                          <div className="space-y-4">
                            <div className="flex items-center">
                              <span className="w-32 font-medium text-gray-600">Pharmacist Code:</span>
                              <span className="font-semibold text-gray-800">-</span>
                            </div>
                            <div className="flex items-center">
                              <span className="w-32 font-medium text-gray-600">Location Code:</span>
                              <span className="font-semibold text-gray-800">{selectedCounterData?.counter_location?.location_code}</span>
                            </div>
                          </div>
                          <div className="space-y-4">
                            <div className="flex items-center">
                              <span className="w-32 font-medium text-gray-600">Location</span>
                              <span className="font-semibold text-gray-800">{selectedCounterData?.counter_location?.location_name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </Tab.Panel>
              <Tab.Panel>
                <OrderDetailsTab />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      )}
    </div>
  );
};

export default OrdersDetailScreen;
