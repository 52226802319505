import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ordActions } from "src/context/order-slice";
import { RootState } from "src/context/store";
import FilterAccordion from "./FilterAccordion";
import { Button } from "src/components";

interface FilterItem {
  id?: string;
  name?: string;
  code?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Add an index signature
}

interface OrdersFilter {
  onCloseClick: () => void;
  companyDetails: FilterItem[];
  divisionDetails: FilterItem[];
  pendingStatus?: FilterItem[];
  stockistDetails: FilterItem[];
}

const Divider = () => <div className="w-full h-[0.0625rem] my-2 bg-[#0000001a]" />;

const OrdersFilter = forwardRef<HTMLElement, OrdersFilter>(({ companyDetails, divisionDetails, pendingStatus, stockistDetails }, ref) => {
  const dispatch = useDispatch();

  const companyFilterHandler = (details: FilterItem[]) => {
    dispatch(ordActions.setOrderFilters({ key: "company", value: details }));
  };

  const divisionFilterHandler = (details: FilterItem[]) => {
    dispatch(ordActions.setOrderFilters({ key: "division", value: details }));
  };

  const statusFilterHandler = (details: FilterItem[]) => {
    dispatch(ordActions.setOrderFilters({ key: "status", value: details }));
  };
  const stockistFilterHandler = (details: FilterItem[]) => {
    dispatch(ordActions.setOrderFilters({ key: "stockist", value: details }));
  };

  const resetFilter = () => {
    dispatch(ordActions.setOrderFilters({ key: "company", value: [] }));
    dispatch(ordActions.setOrderFilters({ key: "division", value: [] }));
    dispatch(ordActions.setOrderFilters({ key: "status", value: [] }));
    dispatch(ordActions.setOrderFilters({ key: "stockist", value: [] }));
    dispatch(ordActions.setIsResetFilter(false));
  };

  const filters = useSelector((state: RootState) => state.ord.OrderFilters);

  return (
    <aside
      className="bg-white border-l-[1px] min-w-[20rem] overflow-y-auto max-w-[25rem] border-l-[#00000014] fixed px-8 text-[#49484C] text-xl font-semibold py-5 right-0  h-screen"
      ref={ref}
    >
      <div className="flex items-center justify-between gap-5 mt-3">
        <h3 className="whitespace-nowrap">Fetch filters</h3>
        <Button className="border py-1 bg-[#7F56D9] text-white" onClick={resetFilter}>
          Reset
        </Button>
      </div>

      {/* filters  */}
      <div className="flex flex-col ">
        <FilterAccordion
          uniqueKey="code"
          displayKey="shortname"
          onSelectingFilter={companyFilterHandler}
          title="Company Name"
          arrayToFilter={companyDetails}
          defaultValue={filters.company}
          isSingleSelect={true}
        />
        <Divider />
        <FilterAccordion
          uniqueKey="division_name"
          displayKey="division_name"
          title="Division"
          arrayToFilter={divisionDetails}
          onSelectingFilter={divisionFilterHandler}
          defaultValue={filters.division}
          isSingleSelect={true}
        />
        <Divider />
        <FilterAccordion
          uniqueKey="stockist_id"
          displayKey="owner_name"
          title="Stockist"
          arrayToFilter={stockistDetails}
          onSelectingFilter={stockistFilterHandler}
          defaultValue={filters.stockist}
          isSingleSelect={true}
        />

        {/* {pendingStatus && pendingStatus?.length > 0 && ( */}
        {
          <>
            <Divider />
            <FilterAccordion
              displayKey="order_status_desc"
              title="Status"
              arrayToFilter={pendingStatus || []}
              uniqueKey="order_status_id"
              onSelectingFilter={statusFilterHandler}
              defaultValue={filters.status}
              isSingleSelect={true}
            />
          </>
        }
      </div>
    </aside>
  );
});

export default OrdersFilter;
