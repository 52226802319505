import InvoiceHomeDetailCard from "./InvoiceHomeDetailCard";
import { getInvoiceDetailsCardInfo } from "../invoice-master/invoice-helper";
import Loader from "src/components/ui/loader.component";

const AllInvoices = ({
  allInvoiceData,
  isExpandable,
  isExpandedInitial,
}: {
  allInvoiceData: any;
  isExpandable?: boolean;
  isExpandedInitial?: boolean;
}) => {
  if (allInvoiceData && allInvoiceData?.length == 0) {
    return <Loader />;
  }

  return (
    <div className="mt-10 w-full">
      <ul>
        {allInvoiceData.map((data: any, index: number) => (
          <li className="my-5 rounded-lg border-2 shadow-sm border-1-black">
            <InvoiceHomeDetailCard
              status={data?.fileStatusHdr?.fuid_status_desc}
              isExpandable={isExpandable}
              isExpandedInitial={isExpandedInitial}
              data={getInvoiceDetailsCardInfo(data)}
              checkDataType={false}
              invoiceData={data}
              viewDetails={true}
              index={index}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AllInvoices;
