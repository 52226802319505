import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { generateUniqueID, getCookieValue } from "src/constants/functions";
import { ApiBlocker, api, changeApiBlocker, changeShowErrorToast, isProd, logout } from "src/constants/global-const";
import { counterActions } from "src/context/counter-slice";
import store from "src/context/store";
import { addAuthHeader } from "src/utils/auth";
import { trimObjectValues } from "src/utils/utils";

let instance: AxiosInstance;
let eventInstance: AxiosInstance;

const axiosInstance = async () => {
  instance = axios.create({
    baseURL: api + "/api/v1/counter",
  });
  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      // set cookie header here
      // changeApiBlocker(false, false);
      // changeShowErrorToast(false, "");

      const params = config.url?.split("?")?.[1];
      console.log(params);
      if (params && params.includes("undefined")) {
        console.error("Request cancelled due to undefined parameters.");
        return Promise.reject(new Error("Request cancelled due to undefined parameters."));
      }

      // ApiBlocker.eventChecked();
      console.log("success normal request", config);
      return addAuthHeader(config);
    },
    (error) => {
      if (error?.response?.status === 403) {
        logout();
      }
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (success: any) => {
      let counterString = "";
      if (success.config.baseURL.includes("counter") && success.data && window.sessionStorage.getItem("counterId")) {
        counterString = `counter_${window.sessionStorage.getItem("counterId")}`;
        counterString && ApiBlocker.eventChecked(counterString);
      }
      return success;
    },
    (error) => {
      // alert("asdfasdf");
      if (error?.response?.status === 403) {
        // logout
        logout();
      }
    }
  );
};

let pendingTimer: any;

const handlePendingState = async (config: any, resolve: any, reject: any, attempt = 0) => {
  const params = new URLSearchParams(window.location.search);

  if (ApiBlocker.isEventPending) {
    if (attempt >= 10) {
      clearTimeout(pendingTimer);
      reject(new Error("Maximum retry attempts reached."));
      return;
    }
    clearTimeout(pendingTimer); // Clear the previous timer
    pendingTimer = setTimeout(async () => {
      const counterId = window.sessionStorage.getItem("doctor_counter_id") || params.get("id");
      if (counterId) {
        await ApiBlocker.eventChecked(`counter_${counterId}`);
      }
      handlePendingState(config, resolve, reject, attempt + 1); // Recursively call the function until the event is not pending
    }, 1000); // Wait for 1 second before checking again
  } else {
    if (config?.data?.data) {
      const payload = structuredClone(config?.data?.data);
      const trimmedPayload = trimObjectValues(payload);
      config.data.data = trimmedPayload;
    }
    resolve(addAuthHeader(config)); // Proceed with the API call if not pending
  }
};

const axiosEventInstance = async () => {
  eventInstance = axios.create({
    baseURL: api + "/event/counter",
  });

  // Add the interceptor to the instance
  eventInstance.interceptors.response.use(
    async (success: any) => {
      try {
        await ApiBlocker.eventChecked(success?.data?.eventStreamCreated?.streamId);
      } catch (error) {
        console.error("Error in ApiBlocker.eventChecked:", error);
      }
      return success;
    },
    (error) => {
      if (error?.response?.status === 403) {
        logout();
      }
    }
  );

  eventInstance.interceptors.request.use(
    (config) => {
      return new Promise(async (resolve, reject) => {
        if (ApiBlocker.isEventBlocked && config.url !== "/add") {
          const errorMessage = "Request cancelled due to event blocked status";
          changeShowErrorToast(true, errorMessage);
          changeApiBlocker(false, true);
          reject(errorMessage);
        } else {
          await handlePendingState(config, resolve, reject, 1);
        }
      });
    },
    (error) => {
      if (error?.response?.status === 403) {
        logout();
      }
      return Promise.reject(error);
    }
  );
};

axiosEventInstance();

axiosInstance();

export const getAllCounterDetails = async (): Promise<AxiosResponse> => {
  return instance.get("/details");
};

export const getAllLinkTypesDetails = async (query: string = ""): Promise<AxiosResponse> => {
  return instance.get("/linktype" + query);
};

export const getAllBlockCategory = async () => instance.get("/block-category");

export const getAllBlockCompanyCategory = async (counter_company_location_id: string | number) =>
  instance.get("/block-category?counter_company_id=" + counter_company_location_id);

export const getAllBlockDivisionCategory = async (counder_ff_id: string | number) => instance.get("/block-category?counder_ff_id=" + counder_ff_id);

export const queryCounterDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get("/search" + query);
};

export const queryCounterDetailsById = async (query: string): Promise<AxiosResponse> => {
  try {
    const response = await instance.get("/details" + query);
    return response;
  } catch (error) {
    // Dispatch an action if the API call fails
    store.dispatch(counterActions.setSelectedCounterData({}));
    throw error;
  }
};

export const getCounterType = async (): Promise<AxiosResponse> => {
  return instance.get("/type");
};

export const addCounter = async (data: any): Promise<AxiosResponse> => {
  return eventInstance.post(
    "/add",
    {
      data: { ...data, u_code: generateUniqueID() },
      event_type: "COUNTER_ADD",
    },
    {
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
    }
  );
};

export const editCounter = async (data: any, primary_key: string | number): Promise<AxiosResponse> => {
  return eventInstance.put(
    "/edit",
    {
      data: { ...data },
      primary_key: primary_key,
      event_type: "COUNTER_EDIT",
    },
    {
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
    }
  );
};

export const convertToHospital = async (data: any, primary_key: string | number): Promise<AxiosResponse> => {
  return eventInstance.post(
    "/replace/ph-to-hs",
    {
      data: { ...data },
      primary_key: primary_key,
      event_type: "COUNTER_REPLACED_FROM_PH_TO_HS_ADD",
    },
    {
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
    }
  );
};

export const getCounterLinkType = async (query?: string): Promise<AxiosResponse> => {
  return instance.get(`/linktype${query ? query : ""}`);
};

export const getCounterDetailsById = async (id: string | number | null | undefined): Promise<AxiosResponse> => {
  return instance.get("/search?id=" + id);
};

export const getPharmacyDetailsById = async (id: string | number | null | undefined): Promise<AxiosResponse> => {
  return instance.get("/search?usertype=3&&id=" + id);
};

export const getPharmacyDetailsByName = async (name: string | number | null | undefined): Promise<AxiosResponse> => {
  return instance.get("/search?usertype=3&name=" + name);
};

export const getPharmacyDetailsByNameAndId = async (name: string | number | null | undefined, counterID?: string): Promise<AxiosResponse> => {
  if (counterID) {
    if (name?.toString()?.toLocaleLowerCase()?.startsWith("dr")) {
      name = name?.toString()?.toLocaleLowerCase();
      name = name?.toString()?.replace("dr", "DR");
      //   return instance.get(`/search?usertype=3,2&nt=${counterID ?? ""}&counter_code=` + name);
      // } else return instance.get(`/search?usertype=3,2&nt=${counterID ?? ""}&name=` + name);
      return instance.get(`/search?usertype=3,2&counter_code=` + name);
    } else return instance.get(`/search?usertype=3,2&name=` + name);
  } else {
    if (name?.toString()?.toLocaleLowerCase()?.startsWith("dr")) {
      name = name?.toString()?.toLocaleLowerCase();
      name = name?.toString()?.replace("dr", "DR");
      return instance.get(`/search?usertype=3,2&counter_code=` + name);
    } else return instance.get(`/search?usertype=3,2&name=` + name);
  }
};

export const getCounterSpeciality = async (): Promise<AxiosResponse> => {
  return instance.get("/speciality");
};

export const AddUpdateLocation = async (data: any, path: string): Promise<AxiosResponse> => {
  return path === "add"
    ? eventInstance.post(
        `/location/${path}`,
        {
          data: data,
          primary_key: data?.counter_id,
          event_type: "COUNTER_LOCATION_ADD",
        },
        {
          validateStatus: function (status) {
            return status < 500 && status >= 200;
          },
        }
      )
    : eventInstance.put(
        `/location/edit`,
        {
          data: data,
          primary_key: data?.counter_id,
          event_type: "COUNTER_LOCATION_EDIT",
        },
        {
          validateStatus: function (status) {
            return status < 500 && status >= 200;
          },
        }
      );
};
export const AddCompany = async (data: any, skipStatusValidation: boolean = false): Promise<AxiosResponse> => {
  return eventInstance.post(
    "/company/add",
    {
      data: data,
      primary_key: data?.counter_id,
      event_type: "COUNTER_COMPANY_ADD",
    },
    {
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
      params: {
        skipStatusValidation: skipStatusValidation,
      },
    },
  );
};
export const getCounterCompanyLinkData = async (data: any): Promise<AxiosResponse> => instance.get(`/companylink?counter_id=${data}`);

export const addCounterDivision = async (data?: any): Promise<AxiosResponse> =>
  eventInstance.post(
    "/division/add",
    {
      data: data,
      primary_key: data?.counter_id,
      event_type: "COUNTER_DIVISION_ADD",
    },
    {
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
    }
  );

export const DeactivateCounterDivision = async (data?: string | number | any, id?: string | number): Promise<AxiosResponse> =>
  eventInstance.delete(`deactivate/division`, {
    data: {
      data: data,
      primary_key: id,
      event_type: "COUNTER_DIVISION_DEACTIVATE",
    },
    validateStatus: function (status) {
      return status < 500 && status >= 200;
    },
  });

export const getCounterValueCap = async (data?: any): Promise<AxiosResponse> =>
  instance.get(
    `value-cap?counter_id=${data?.counter_id}&counter_company_location_id=${data?.counter_company_location_id}&counter_location_id=${data?.counter_location_id}`
  );

export const getDelegates = async (data: string | number): Promise<AxiosResponse> => instance.get(`/delegate?counter_id=${data}`);

export const setValueCap = async (data?: string | number | object | any): Promise<AxiosResponse> =>
  eventInstance.post(
    `/ordercap/add`,
    {
      data: data,
      primary_key: data?.counter_id,
      event_type: "COUNTER_ORDERCAP_ADD",
    },
    {
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
    }
  );

export const editValueCap = async (data?: string | number | object | any): Promise<AxiosResponse> =>
  eventInstance.put(
    `/ordercap/edit`,
    {
      data: data,
      primary_key: data?.counter_id,
      event_type: "COUNTER_ORDERCAP_EDIT",
    },
    {
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
    }
  );

export const addDelegate = async (data?: any): Promise<AxiosResponse> =>
  eventInstance.post(
    `/delegate/add`,
    {
      data: { ...data, u_code: generateUniqueID() },
      primary_key: data?.counter_hdr_id,
      event_type: "COUNTER_DELEGATE_ADD",
    },
    {
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
    }
  );

export const getHospitalName = async () => instance.get("/search?usertype=2");

export const blockCounterDivision = async (data?: any, id?: any): Promise<AxiosResponse> =>
  eventInstance.post(`/block`, {
    data: data,
    primary_key: id,
    event_type: "COUNTER_DIVISION_BLOCK_ADD",
  });

export const getBlockCounterList = async (
  counter_id: number | string,
  counter_company_location_id?: string | number,
  counter_location_id?: string | number
) => {
  if (!counter_company_location_id && !counter_location_id) {
    return instance.get(`/blocked-revoked/list?type=B&counter_id=${counter_id}`);
  } else {
    return instance.get(
      `/blocked-revoked/list?type=B&counter_id=${counter_id}&counter_company_location_id=${counter_company_location_id}&counter_location_id=${counter_location_id}`
    );
  }
};

export const getRevokeCounterList = async (
  counter_id: number | string,
  counter_company_location_id?: string | number,
  counter_location_id?: string | number
) => {
  if (!counter_company_location_id && !counter_location_id) {
    return instance.get(`/blocked-revoked/list?type=R&counter_id=${counter_id}`);
  } else {
    return instance.get(
      `/blocked-revoked/list?type=R&counter_id=${counter_id}&counter_company_location_id=${counter_company_location_id}&counter_location_id=${counter_location_id}`
    );
  }
};

export const revokeCounter = async (data: any, id?: any, isDivisionType?: any) => {
  return eventInstance.post(`/revoke`, {
    data: data,
    primary_key: id,
    event_type: isDivisionType ? "COUNTER_DIVISION_REVOKE_ADD" : "COUNTER_COMPANY_REVOKE_ADD", // Similarly You need to add COUNTER_COMPANY_REVOKE
  });
};

export const getStockistData = async (data: any) => {
  return instance.get(`/stockist-link/search?counter_id=${data}`);
};

export const getStockistList = async (data: any) => {
  return instance.get(`/stockist-link/?counter_id=${data}`);
};

export const getStockistByCityCode = async (data: any) => {
  return instance.get(`/stockist/?city_code=${data}`);
};
export const getStockistByCluster = async (cluster_code: number | string) => {
  return instance.get(`/stockist/?company=true&cluster=true&cluster_code=${cluster_code}`);
};

export const blockCounterCompany = async (data: any) => {
  return eventInstance.post("/block", {
    data: data,
    primary_key: data?.counter_id,
    event_type: "COUNTER_COMPANY_BLOCK_ADD",
  });
};

export const checkUinRequired = async (data: any, token: any) => {
  if (!token) throw new Error();
  return axios.post(`${isProd ? "https://appsapigwv2.medvol" : "https://devappapigwv2.medvol"}.in/api/CompanyUINValidation_Required`, data, {
    headers: {
      Authorization: token,
      // Authorization: isProd ? `${token}` : `Bearer ${token}`,
    },
  });
};
// https://devappsapigw.medvol

export const validateUin = async (data: any, token: any) => {
  if (!token) throw new Error();
  return axios.post(`${isProd ? "https://appsapigwv2.medvol.in" : "https://devappapigwv2.medvol.in"}/api/CompanyUINValidation`, data, {
    headers: {
      Authorization: token,
    },
  });
};

export const getStockistSearchData = async (data: any) => {
  return instance.get(`/stockist-link/search?${data}`);
};

export const addStockistToCounter = async (data: any) => {
  return eventInstance.post(`/stockist/add`, {
    data: { ...data, u_code: generateUniqueID() },
    primary_key: data?.counter_hdr_id,
    event_type: "COUNTER_STOCKIST_ADD",
  });
};

export const getCounterProducts = async (data: any) => {
  return instance.get(`/product?counter_company_location_id=${data}`);
};

export const deletePostStockist = async (data: any, id?: any) => {
  return eventInstance.put(`/stockist/remove`, {
    data: data,
    primary_key: id,
    event_type: "COUNTER_STOCKIST_REMOVE",
  });
};

export const updateStockistStatus = async (data: any, id?: any) => {
  return eventInstance.put(`/stockist/active-inactive`, {
    data: data,
    primary_key: id,
    event_type: "COUNTER_STOCKIST_EDIT",
  });
};

export const deletePostProducts = async (data: any) => {
  return eventInstance.delete(`product/delete`, {
    data: {
      data: data,
      primary_key: data?.counter_id,
      event_type: "COUNTER_PRODUCT_DELETE",
    },
  });
};

export const postProductAdd = async (data: any) => {
  return eventInstance.post(`/product/add`, {
    data: { ...data, u_code: generateUniqueID() },
    primary_key: data?.counter_id,
    event_type: "COUNTER_PRODUCT_ADD",
  });
};

export const deleteCounterLocation = async (data: any, primary_key: string | number) => {
  return eventInstance.delete(`/location/delete`, {
    data: {
      data: data,
      primary_key: primary_key, //Counter's PK
      event_type: "COUNTER_LOCATION_DELETE",
    },
    validateStatus: function (status) {
      return status < 500 && status >= 200;
    },
  });
};

export const getCounterRequestById = async (id: string | number): Promise<AxiosResponse> => {
  return axios.get(`${api}/api/v1/cda/counter/request/details?counterRequest_dtl_id=${id}`, {
    headers: {
      Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
    },
  });
};

export const getCounterRequestByCreq = async (id: string | number): Promise<AxiosResponse> => {
  return axios.get(`${api}/api/v1/cda/counter/request/details?counter_request_code=${id}`, {
    headers: {
      Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
    },
  });
};

export const getCanImportCreq = async (id: string | number): Promise<AxiosResponse> => {
  return axios.get(`${api}/api/v1/cda/last-action?creqId=${id}`, {
    headers: {
      Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
    },
  });
};

export const addCompanyDelegate = async (data?: any): Promise<AxiosResponse> => {
  return eventInstance.post(`/delegate/company/add`, {
    data: { ...data, u_code: generateUniqueID() },
    primary_key: data?.counter_id,
    event_type: "COUNTER_DELEGATE_COMPANY_ADD",
  });
};

export const removeCompanyDelegate = async (data?: any): Promise<AxiosResponse> => {
  return eventInstance.delete(`/delegate/company/remove`, {
    data: {
      data: { ...data },
      primary_key: data?.counter_id,
      event_type: "COUNTER_DELEGATE_COMPANY_EDIT",
    },
    validateStatus: function (status) {
      return status < 500 && status >= 200;
    },
  });
};

export const putUpdateProductCounter = async (data?: any): Promise<AxiosResponse> => {
  return eventInstance.put(`/product/edit`, {
    data: { ...data },
    primary_key: data?.counter_id,
    event_type: "COUNTER_PRODUCT_EDIT",
  });
};

export const removeDelegate = async (data: any, primary_key: number | string): Promise<AxiosResponse> => {
  return eventInstance.delete(`/delegate/remove`, {
    data: {
      data: { ...data },
      primary_key: primary_key,
      event_type: "COUNTER_DELEGATE_EDIT",
    },
    validateStatus: function (status) {
      return status < 500 && status >= 200;
    },
  });
};
export const EditDivision = async (data: any, primary_key: number | string): Promise<AxiosResponse> => {
  return eventInstance.put(
    `/division/edit`,
    {
      data: data,
      primary_key: primary_key,
      event_type: "COUNTER_DIVISION_EDIT",
    },
    {
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
    }
  );
};

export const deactivateCompany = async (data: any, primary_key: number | string): Promise<AxiosResponse> => {
  return eventInstance.delete(`/company/deactivate`, {
    data: { data: data, primary_key: primary_key, event_type: "COUNTER_COMPANY_DELETE" },
    validateStatus: function (status) {
      return status < 500 && status >= 200;
    },
  });
};
export const deactivateOrderCap = async (data: any, primary_key: number | string): Promise<AxiosResponse> => {
  return eventInstance.delete(`/ordercap/deactivate`, {
    data: { data: data, primary_key: primary_key, event_type: "COUNTER_ORDERCAP_DEACTIVATE" },
  });
};

export const checkMci = async (email: string) => {
  return instance.get(`validate/mci-registration-number?mci_register_number=${email}`);
};

export const putCounterCompanyMobile = async (data: any, primary_key: number | string): Promise<AxiosResponse> => {
  return eventInstance.put(`/company/edit`, {
    data,
    primary_key: primary_key,
    event_type: "COUNTER_COMPANY_EDIT",
  });
};

export const postCreateHospital = async (data: any): Promise<AxiosResponse> => {
  return axios.post(
    `${api}/event/hospital/add`,
    {
      data: { ...data, u_code: generateUniqueID() },
      event_type: "HOSPITAL_ADD",
    },
    {
      headers: {
        Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
      },
    }
  );
};

export const editCounterCompany = async (data: any, primary_key: number | string): Promise<AxiosResponse> => {
  return eventInstance.put(`/company/edit`, {
    data,
    primary_key: primary_key,
    event_type: "COUNTER_COMPANY_EDIT",
  });
};

export const getSearchHospitalByName = async (data: any): Promise<AxiosResponse> => {
  return axios.get(`${api}/api/v1/hospital/search?hospital_name=${data}`, {
    headers: {
      Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
    },
  });
};

export const getSearchHospitalById = async (data: any): Promise<AxiosResponse> => {
  return axios.get(`${api}/api/v1/hospital/search?hospital_id=${data}`, {
    headers: {
      Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
    },
  });
};

export const putUpdateHospital = async (data: any, primary_key: number | string): Promise<AxiosResponse> => {
  return axios.put(
    `${api}/event/hospital/edit`,
    {
      data,
      event_type: "HOSPITAL_EDIT",
      primary_key,
    },
    {
      headers: {
        Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
      },
    }
  );
};

export const deactivateCounter = async (data: any): Promise<AxiosResponse> => {
  return eventInstance.delete(`/deactivate`, {
    data: { data: data, event_type: "COUNTER_DEACTIVATE", primary_key: data?.counter_id },
    validateStatus: function (status) {
      return status < 500 && status >= 200;
    },
  });
};

export const getCounterValueCapValidation = async (data: any): Promise<AxiosResponse> => {
  return instance.post(`/valuecap/validation`, data);
};

export const getCounterActionsValidation = async (data: any): Promise<AxiosResponse> => {
  return instance.post(`/counteraction/validation`, data);
};

export const getCounterProductsValidation = async (data: any): Promise<AxiosResponse> => {
  return instance.post(`/counterproduct/validation`, data);
};

export const getCounterBlockValidations = async (data: any): Promise<AxiosResponse> => {
  return instance.post(`/counterblock/validation`, data);
};

export const mergeCounter = (data: any) => {
  return eventInstance.post(
    `merge`,
    {
      data: data,
      primary_key: data.second_counter_id,
      event_type: "COUNTER_REPLACE_ADD",
    },
    {
      validateStatus: function (status) {
        return status < 500 && status >= 200;
      },
    }
  );
};

export const getDraphComapnies = (counter_id: number | string) => {
  return instance.get(`draph?pharmacy_id=${counter_id}&format=true`);
};

export const getIndphPharmacies = (counter_id: number | string) => {
  return instance.get(`drnt?assc_counterhdr_id=${counter_id}&format=true`);
};

export const getLocationOfPharmacy = (location_id: number | string) => {
  return instance.get(`/location/toadd?location_id=${location_id}`);
};

export const counterLink = async (data: any, primary_key: string | null): Promise<AxiosResponse> => {
  return axios.put(
    `${api}/event/counter/link`,
    { data, event_type: "CDA_COUNTER_ADD", primary_key: primary_key },
    {
      headers: {
        Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
      },
    }
  );
};

export const getCounterCategoryList = async (): Promise<AxiosResponse> => {
  return instance.get(`${api}/api/v1/counter/countercategory_list`);
};

export const getAttachmentBindingKeys = async (): Promise<AxiosResponse> => {
  return instance.get("/attachmentKeys");
};

export const queryAttachmentProofsByCounterId = async (query: string): Promise<AxiosResponse> => {
  try {
    const response = await instance.get("/attachmentProofs" + query);
    return response;
  } catch (error) {
    // Dispatch an action if the API call fails
    store.dispatch(counterActions.setSelectedCounterAttachments([]));
    throw error;
  }
};

export const uploadFilesToS3 = (data: any) => instance.post("upload-file", data);

export const getCounterTag = async (data: any): Promise<AxiosResponse> => {
  return instance.get(`/getCounterTagsBasedOnCompany?company_code=${data}`);
};
