import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "@radix-ui/react-tooltip";

const LocationCard = ({ CardData }: { CardData: any }) => {
  return (
    <div className="grid grid-cols-2 p-4 text-base gap-y-3">
      {Object.entries(CardData)?.map(([key, value]) => (
        <div key={key?.toString()} className="grid flex-row grid-cols-2 ">
          <p className="text-[#49484C] capitalize place-self-center mr-auto whitespace-break-spaces text-base leading-[0.02rem] font-normal not-italic">
            {key}
          </p>
          <div className="mr-auto">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="cursor-text" asChild>
                  <p className="text-[#1B1A1F] text-base font-medium text-left mr-auto">{String(value).slice(0, 20)}</p>
                </TooltipTrigger>
                <TooltipContent className="p-1 text-white bg-black rounded-md">
                  <p className="mr-auto text-white">{typeof value === "number" || typeof value === "string" ? value : "N/A"}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LocationCard;
