import { Button } from "src/components";
import classNames from "classnames";
import { Tab } from "@headlessui/react";
import downArrow from "../../assets/down-arrow.svg";
import InvoiceHeader from "./invoice-header.component";
import InvoiceProductMapping from "./invoice-product-mapping.component";

const tabs = ["Header Comparison", "Product Mapping"];

const InvoiceComparison = () => {
  return (
    <div>
      <header className="flex justify-between w-full max-w-[1400px] mx-auto mt-10">
        <div className="flex items-center">
          <h1 className="font-semibold text-[1.5rem] mr-4">INVOICE #123-456</h1>
          <h1 className="font-semibold  flex items-center text-xs h-6 px-2 rounded-full bg-[#cdfee1] text-[#0C5132]">
            Accepted
            <img className="w-3 h-3 ml-1" src={downArrow} alt="down-arrow" />
          </h1>
        </div>
        <div className="flex items-center gap-x-2">
          {
            <div className="flex items-center p-2 rounded-md bg-[#f1f1f1]">
              <Button className="flex items-center px-4 space-x-2 font-normal bg-[#f1f1f1] border-gray-500 rounded-r-none border-r rounded-l-md h-5 hover:text-gray-600 focus:outline-none focus:ring-0">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a2 2 0 002 2h12a2 2 0 002-2v-1M7 8l5 5m0 0l5-5m-5 5V3" />
                </svg>
                <span>Download Invoice</span>
              </Button>
              <Button className="flex items-center px-4 space-x-2 font-normal bg-[#f1f1f1]  border-none rounded-none h-5 hover:text-gray-600 focus:outline-none focus:ring-0">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14M12 5l7 7-7 7" />
                </svg>
                <span>Preview</span>
              </Button>
              <Button className="flex items-center px-4 space-x-2 font-normal bg-[#f1f1f1] border-gray-500 border-l rounded-l-none rounded-r-md h-5 hover:text-gray-600 focus:outline-none focus:ring-0">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 5.636a9 9 0 11-12.728 0m12.728 0L12 12m0 0l-6.364-6.364" />
                </svg>
                <span>Report an issue</span>
              </Button>
            </div>
          }
        </div>
      </header>
      <div className="w-full mx-auto mt-10 max-w-[1400px] p-6 border border-gray-300 shadow-lg rounded-lg">
        <Tab.Group>
          <Tab.List className="flex p-1 space-x-1 rounded-lg bg-[#e3e3e3] h-[38px] items-center">
            {tabs.map((tab) => (
              <Tab
                key={tab}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-md py-1.5 text-sm font-normal leading-5",
                    "focus:outline-none ",
                    selected ? "bg-white" : "hover:bg-white/[0.12] hover:text-black"
                  )
                }
              >
                {tab}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel className={classNames("rounded-xl w-full bg-white", "ring-white/60 ring-offset-2 focus:outline-none focus:ring-2")}>
              <InvoiceHeader />
            </Tab.Panel>
            <Tab.Panel className={classNames("rounded-xl bg-white", "ring-white/60 ring-offset-2 focus:outline-none focus:ring-2")}>
              <InvoiceProductMapping />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default InvoiceComparison;
