import { Button } from "src/components";
import { useState } from "react";
import downArrow from "src/assets/down-arrow.svg";

const ORD4 = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className="max-w-[1400px] mt-6 w-full mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white">
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="flex gap-6 items-center">
              <p className="font-semibold text-[1.3rem]">FUID #123-456</p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold flex items-center w-fit mt-2 text-[.8rem] h-6 px-2 rounded-full bg-[#FFF1E3] text-[#5E4200]">On hold</p>
              <p className="font-semibold flex items-center w-fit mt-2 text-[.8rem] h-6 px-2 rounded-full bg-[#EAF4FF] text-[#002E6A]">ORD#123456</p>
            </div>
          </div>
          <div className="flex gap-2">
            <Button className="font-normal text-white bg-[#8051FF]">Download</Button>
            <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
              <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
            </Button>
          </div>
        </div>{" "}
        <div className="border-t mt-6 border-[#d0d0d0]"></div>
        <div className="flex gap-10">
          <div className="mt-4 space-y-4 w-1/2">
            <div className="text-[1rem] flex gap-20">
              <p className="text-[#616161]">Uploaded on</p>
              <p className="text-[#303030]">21 June 2024</p>
            </div>
            <div className="text-[1rem] flex gap-20">
              <p className="text-[#616161]">Uploaded by</p>
              <p className="text-[#303030]">Dr. Shreevansh Kumar</p>
            </div>
          </div>
          <div className="mt-4 space-y-4 w-1/2">
            <p className="font-semibold text-[1.2rem]">Remarks</p>
            <p className="text-[#616161] inline-block mr-20">Category</p>
            <p className="text-[#303030] inline-block">Wrong Billing Address</p>
            <div className="border space-y-1 mt-6 border-[#d0d0d0] min-h-[6rem] min-w-[20rem] w-fit rounded-lg bg-[#F1F1F1] p-4">
              <p className="text-[#7F56D9] font-normal text-[1rem]">Medvol Admin</p>
              <p className="text-[#303030] font-normal text-[1rem]">Order generated. (100 character limit)</p>
              <p className="text-[#616161] font-normal text-[.8rem]">20 June 2024</p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1400px] mt-6 w-full mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white">
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="flex gap-6 items-center">
              <p className="font-semibold text-[1.3rem]">FUID #123-456</p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold flex items-center w-fit mt-2 text-[.8rem] h-6 px-2 rounded-full bg-[#FFF1E3] text-[#5E4200]">On hold</p>
              <p className="font-semibold flex items-center w-fit mt-2 text-[.8rem] h-6 px-2 rounded-full bg-[#EAF4FF] text-[#002E6A]">ORD#123456</p>
            </div>
          </div>
          <div className="flex gap-2">
            <Button className="font-normal text-white bg-[#8051FF]">Download</Button>
            <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
              <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
            </Button>
          </div>
        </div>{" "}
      </div>
      <div className="max-w-[1400px] mt-6 w-full mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white">
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="flex gap-6 items-center">
              <p className="font-semibold text-[1.3rem]">FUID #123-456</p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold flex items-center w-fit mt-2 text-[.8rem] h-6 px-2 rounded-full bg-[#FFF1E3] text-[#5E4200]">On hold</p>
              <p className="font-semibold flex items-center w-fit mt-2 text-[.8rem] h-6 px-2 rounded-full bg-[#EAF4FF] text-[#002E6A]">ORD#123456</p>
            </div>
          </div>
          <div className="flex gap-2">
            <Button className="font-normal text-white bg-[#8051FF]">Download</Button>
            <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
              <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
            </Button>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default ORD4;
