import axios, { AxiosInstance } from "axios";
import { api, emailMobileCheck, logout } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

let instance: AxiosInstance;
// let eventInstance: AxiosInstance;

const axiosInstance = async () => {
  instance = axios.create({
    baseURL: api + "/api/v1/common",
  });
  // Add the interceptor to the instance
  instance.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 403) {
      // logout
      logout();
    }
  });

  instance.interceptors.request.use((config) => addAuthHeader(config));
};

axiosInstance();

interface ApiResponse {
  success: boolean;
  message: string;
  data?: any;
  status: number | string;
  // Add other properties as needed
}

export const checkMobileNo = async (mobileNo: string) => {
  // return instance.get(`/check-mobile?mobile=${mobileNo}`);
  return axios.get(`${emailMobileCheck}?mobile_no=${mobileNo}`);
};

export const checkEmail = async (email: string) => {
  return axios.get(`${emailMobileCheck}?email=${email}`);

  // return instance.get(`/check-email?email=${email}`);
};

export const getCounterTypes = async (query?: string): Promise<ApiResponse> => {
  if (query) return instance.get("/getModuleTypes" + query);
  return instance.get("/getModuleTypes");
};
