import { Tab } from "@headlessui/react";
import InvoiceCard from "./invoice-card";
import AllInvoices from "../invoice-homepage/invoice-all.component";
import classNames from "classnames";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "src/components/ui/select.component";

let category = ["All invoices", "Priority tasks", "Escalated"];

const dummyInvoiceData = [
  {
    "Invoice #": "#123-456",
    Status: "Waiting for Approval",
    "FUID no.": "#123-456-789",
    Company: "Sun Pharmaceuticals",
    Resource: "Sun Pharmaceuticals",
    "File Upload Date": "6 August 2024, 11:05:20 AM",
    Stockist: "Dr. Reddy's Laboratories",
    "Stockist code (Cmp)": "#123-456-789",
    "Stockist code (MV)": "#123-456-789",
    "Ref. Order no.": "#123-456-789-123-456-789",
    "E-Inv. Applicability": "Yes",
    "Inv. Automation": "Yes",
  },
  {
    "Invoice #": "#123-456",
    Status: "Waiting for Approval",
    "FUID no.": "#123-456-789",
    Company: "Sun Pharmaceuticals",
    Resource: "Sun Pharmaceuticals",
    "File Upload Date": "6 August 2024, 11:05:20 AM",
    Stockist: "Dr. Reddy's Laboratories",
    "Stockist code (Cmp)": "#123-456-789",
    "Stockist code (MV)": "#123-456-789",
    "Ref. Order no.": "#123-456-789-123-456-789",
    "E-Inv. Applicability": "Yes",
    "Inv. Automation": "Yes",
  },
  {
    "Invoice #": "#123-456",
    Status: "Waiting for Approval",
    "FUID no.": "#123-456-789",
    Company: "Sun Pharmaceuticals",
    Resource: "Sun Pharmaceuticals",
    "File Upload Date": "6 August 2024, 11:05:20 AM",
    Stockist: "Dr. Reddy's Laboratories",
    "Stockist code (Cmp)": "#123-456-789",
    "Stockist code (MV)": "#123-456-789",
    "Ref. Order no.": "#123-456-789-123-456-789",
    "E-Inv. Applicability": "Yes",
    "Inv. Automation": "Yes",
  },
  {
    "Invoice #": "#123-456",
    Status: "Waiting for Approval",
    "FUID no.": "#123-456-789",
    Company: "Sun Pharmaceuticals",
    Resource: "Sun Pharmaceuticals",
    "File Upload Date": "6 August 2024, 11:05:20 AM",
    Stockist: "Dr. Reddy's Laboratories",
    "Stockist code (Cmp)": "#123-456-789",
    "Stockist code (MV)": "#123-456-789",
    "Ref. Order no.": "#123-456-789-123-456-789",
    "E-Inv. Applicability": "Yes",
    "Inv. Automation": "Yes",
  },
];

const InvoiceApproval = () => {
  return (
    <div className="w-[1312px] mx-auto">
      <section className="flex flex-wrap justify-between mx-auto mt-10 w-full">
        <InvoiceCard title={"4,204"} subtitle="New invoices this month" heading="Total invoices" />
        <InvoiceCard title={"21"} subtitle="Invoices this month" heading="Invoices escalated" />
        <InvoiceCard title={"4,024"} subtitle="New invoices this month" heading="MTD" />
        <InvoiceCard title={"4,204"} subtitle="New invoices this month" heading="Total invoices" />
      </section>
      <div className="px-2 py-16 w-full sm:px-0">
        <Tab.Group>
          <div className="flex justify-between">
            <Tab.List className="flex p-1 space-x-1 rounded-lg bg-[#e3e3e3] w-[500px] h-[38px] items-center">
              {category.map((category) => (
                <Tab
                  key={category}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-md py-1.5 text-sm font-normal leading-5",
                      "focus:outline-none ",
                      selected ? "bg-white" : "hover:bg-white/[0.12] hover:text-black"
                    )
                  }
                >
                  {category}
                </Tab>
              ))}
            </Tab.List>
            <div className="flex gap-2 h-full">
              <div className="flex items-center w-[400px]">
                {/* Company Dropdown */}
                <Select>
                  <SelectTrigger className="w-[150px] shadow-none text-gray-700 bg-[#F1F1F1] border-none rounded-r-none rounded-l-md border-r-2 border-gray-500 focus:outline-none focus:ring-0">
                    <SelectValue placeholder="Company" />
                  </SelectTrigger>
                  <SelectContent className="bg-white">
                    <SelectGroup>
                      <SelectLabel>Companies</SelectLabel>
                      <SelectItem value="company1">Company 1</SelectItem>
                      <SelectItem value="company2">Company 2</SelectItem>
                      <SelectItem value="company3">Company 3</SelectItem>
                      <SelectItem value="company4">Company 4</SelectItem>
                      <SelectItem value="company5">Company 5</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
                {/* <Input placeholder="Search" className="w-full py-2 bg-[#F1F1F1] border-none" /> */}
                <input
                  placeholder="Search"
                  className="w-full px-2 py-1.5 text-gray-700 bg-[#F1F1F1] border-none rounded-l-none rounded-r-md focus:outline-none focus:ring-0"
                />
              </div>
              <Select>
                <SelectTrigger className="w-[160px] bg-[#F1F1F1]">
                  <SelectValue placeholder="Assignee: Vishnu" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  <SelectGroup>
                    <SelectLabel>Assignee</SelectLabel>
                    <SelectItem value="apple">Vishnu</SelectItem>
                    <SelectItem value="banana">Sonika</SelectItem>
                    <SelectItem value="blueberry">Raunak</SelectItem>
                    <SelectItem value="grapes">Rishit</SelectItem>
                    <SelectItem value="pineapple">Tushar</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <Tab.Panels className="mt-2">
            <Tab.Panel className={classNames("w-full bg-white rounded-xl", "ring-offset-2 ring-white/60 focus:outline-none focus:ring-2")}>
              <AllInvoices allInvoiceData={dummyInvoiceData} />
            </Tab.Panel>
            <Tab.Panel className={classNames("bg-white rounded-xl", "ring-offset-2 ring-white/60 focus:outline-none focus:ring-2")}>
              <AllInvoices allInvoiceData={dummyInvoiceData} />
            </Tab.Panel>
            <Tab.Panel className={classNames("bg-white rounded-xl", "ring-offset-2 ring-white/60 focus:outline-none focus:ring-2")}>
              <AllInvoices allInvoiceData={dummyInvoiceData} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default InvoiceApproval;
