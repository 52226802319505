import { Autocomplete, Button } from "src/components";
import crossIcon from "../../assets/cross.svg";
import { useEffect, useState } from "react";
import { getAssigneeList, reAssignInvoice } from "src/services/invoice-api";
import { useToast } from "src/hooks/useToast";

const InvoiceReassign = ({ onCloseModal, invoiceData }: { onCloseModal: () => void; invoiceData: any }) => {
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [reassigneeId, setReassigneeId] = useState<number>();

  const { toast } = useToast();

  const fetchAssigneeList = async () => {
    try {
      const res = await getAssigneeList();
      setAssigneeList(res?.data?.data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssigneeList();
  }, []);

  const handleReassign = async () => {
    const payload = {
      data: {
        stockist_invoice_attachment_id: invoiceData?.stockist_invoice_attachment_id,
        reassign_to: reassigneeId,
      },
      primary_key: invoiceData?.stockist_invoice_attachment_id,
      event_type: "STOCKIST_ATTACHMENT_REASSIGN",
    };
    try {
      if (!reassigneeId) {
        toast({ description: "Please select a assignee", variant: "destructive" });
        return;
      }
      const res = await reAssignInvoice(payload);
      console.log(res);
      if (res?.data?.status === "success") {
        toast({ description: "Invoice reassigned successfully", variant: "default" });
        onCloseModal();
      }
      console.log(payload);
    } catch (error: any) {
      console.log(error);
    } finally {
      onCloseModal();
    }
  };

  return (
    <div className="flex flex-col gap-y-2 w-[500px] mx-auto bg-white p-4 border border-gray-300 shadow-lg rounded-lg">
      <div className="flex justify-between items-center">
        <p className="text-normal py-1 font-semibold text-[#1B1A1F]">Re-assign Invoice</p>
        <Button className="bg-transparent -mr-3" onClick={onCloseModal}>
          <img src={crossIcon} alt="cross" className="w-5 h-5 font-bold invert cursor-pointer" />
        </Button>
      </div>
      <p className="text-sm mb-2 font-normal text-[#666666]">Select a assignee</p>
      <Autocomplete
        inputClassName="w-full"
        placeholder="Select a assignee"
        suggestionList={assigneeList}
        displayKey="user_name"
        uniqueKey="invoice_assignment_id"
        onSelectionChange={(e: any) => setReassigneeId(e?.invoice_assignment_id)}
      />
      <Button className="text-white bg-[#8051FF] mt-4" onClick={handleReassign}>
        Proceed
      </Button>
    </div>
  );
};

export default InvoiceReassign;
