import React, { useState } from "react";
import Button from "../../components/Button";
import SmartForm from "../../components/SmartForm";
import { putAcceptOrderRequest, putRejectOrderRequest } from "src/services/order-api";
import { RootState } from "src/context/store";
import { useSelector } from "react-redux";
import { useToast } from "src/hooks/useToast";

interface OrderApprovalFormProps {
  type: boolean;
  order_id: number;
  closeModal: (modalName: string) => void;
  onSuccess: () => void;
  position_code: string;
}

const OrderApprovalForm: React.FC<OrderApprovalFormProps> = ({ type, order_id, closeModal, onSuccess, position_code }) => {
  const { selectedCounterData } = useSelector((state: RootState) => state.ord);
  const [remarks, setRemarks] = useState("");
  const { toast } = useToast();

  async function acceptRequestHandler(data: any) {
    try {
      const payload = {
        data: {
          position_code: position_code,
          order_id: order_id,
          action: "approve",
          remarks: data?.remarks,
        },
        event_type: "ORDER_APPROVE",
        primary_key: order_id,
      };
      console.log(order_id);
      const res = await putAcceptOrderRequest(payload, selectedCounterData?.company_code);

      if (res.status !== 200) throw new Error();
      closeModal("approveCounter");
      setTimeout(() => {
        onSuccess();
      }, 1500);
      toast({
        description: "Order has been moved to next level for approval",
        variant: "default",
      });
    } catch (error: any) {
      console.log(error);
      closeModal("approveCounter");
      toast({
        description: error.message,
        variant: "destructive",
      });
    }
  }

  async function rejectRequestHandler(data: any) {
    try {
      const payload = {
        data: {
          position_code: position_code,
          order_id: order_id,
          remarks: data?.remarks,
        },
        event_type: "ORDER_REJECT",
        primary_key: order_id,
      };

      const res = await putRejectOrderRequest(payload, selectedCounterData?.company_code);

      if (res.status !== 200) throw new Error();
      closeModal("rejectCounter");
      setTimeout(() => {
        onSuccess();
      }, 1500);
      toast({
        description: "Order has been rejected successfully",
        variant: "default",
      });
    } catch (error: any) {
      toast({
        description: "Failed to reject order",
        variant: "destructive",
      });
    }
  }

  return (
    <SmartForm
      isControlled={false}
      defaultValues={{}}
      onFormSubmit={(data: any) => {
        type ? acceptRequestHandler(data) : rejectRequestHandler(data);
      }}
    >
      <section className="flex flex-col gap-2 px-5 py-5 w-100">
        <SmartForm.TextArea
          type="text"
          value={remarks}
          onChange={(e: any) => {
            console.log(e.target.value);

            setRemarks(e.target.value);
          }}
          className=""
          placeholder="Type comment here..."
          fieldName="remarks"
          label="Comments"
        />
      </section>
      <footer className="border border-t-[1px] flex justify-end px-5 py-3 gap-3 rounded-bl-2xl rounded-br-2xl">
        <Button className="border" onClick={() => (type ? closeModal("approveCounter") : closeModal("rejectCounter"))}>
          Cancel
        </Button>
        <Button type="submit" className="text-white bg-[#586AF5]">
          Submit
        </Button>
      </footer>
    </SmartForm>
  );
};

export default OrderApprovalForm;
