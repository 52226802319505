import { Fragment } from "react";
import { TimeLineChip } from "src/features/fields/components/TimeLineChip";

const approvalHierarchyData = [
  {
    Id: 1,
    ff_level: "L1 Approval",
    emp_name: "Jagdish Choudhary",
    emp_shortcode: "Chennai",
    request_date: "February 21st 2021",
    operation_status: "complete",
    operation_list: "complete",
  },
  {
    Id: 2,
    ff_level: "L2 Approval",
    emp_name: "Jagdish Choudhary",
    emp_shortcode: "Chennai",
    request_date: "February 21st 2021",
    operation_status: "accept",
    operation_list: "complete",
  },
  {
    Id: 3,
    ff_level: "L3 Approval",
    emp_name: "Jagdish Choudhary",
    emp_shortcode: "Chennai",
    request_date: "February 21st 2021",
    operation_status: "reject",
    operation_list: "reject",
  },
];
const ORD8 = () => {
  return (
    <div className="max-w-[1400px] w-full mt-6 mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white">
      {approvalHierarchyData?.map((item: any) => {
        if (item?.operation_list?.length === 1 && item?.operation_list?.[0]?.toString()?.toLowerCase() === "viewer") return <></>;
        return (
          <Fragment key={item?.Id}>
            <TimeLineChip
              title={item?.ff_level}
              name={item?.emp_name || item?.staff_name}
              location={item?.emp_shortcode}
              dateTime={item?.request_date}
              status={item?.operation_status}
              preq_status={item?.operation_list}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default ORD8;
