import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/features/fields/components/Tooltip";
import { switchString } from "src/utils/utils";
import receipt from "src/features/fields/assets/receipt.svg";
import pdf from "src/features/fields/assets/pdf.svg";
import download from "src/features/fields/assets/download.svg";
import check from "src/features/fields/assets/check.svg";

const orderData = {
  "Counter name": "Amrit Lal Bindlish",
  Location: "Open Map",
  "Order Type": "Credit Note / Regular Order",
  "Counter code": "DR12345",
  City: "-",
  "Order Initiated date": "21 June 2024, 11:30AM",
  "Counter location": "Gujarat",
  "Division name": "<division-name>",
  "Order Placed date": "21 June 2024, 11:30AM",
  "Clinic name": "Medicare",
  "Stockist name": "Dr. Shreevansh Kumar",
  "Order Value": "₹100,000",
  "Link Type": "DRDS",
  "Stockist code": "#123-456-789",
  "Ordered by": "Doctor",
};

const ORD1 = () => {
  return (
    <div className="p-6 max-w-[1400px] mx-auto mt-10 border border-gray-300 rounded-xl">
      <h1 className="text-[#49484C] border-b-2 border-gray-200 pb-5 font-semibold text-[1.5rem]">
        <img src={receipt} alt="receipt" className="inline mr-2 w-6 h-6" />
        Order Info
      </h1>
      <p className="text-[#49484C] font-semibold mt-8 text-lg">Summary</p>
      <div>
        <div className={"grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 w-[100%] py-5 xl:grid-cols-3 gap-y-5 mb-5"}>
          {Object.entries(orderData)?.map(
            ([key, value]) =>
              typeof value === "string" &&
              value !== "" && (
                <div key={key?.toString()} className={"grid grid-cols-2 text-sm xl:grid-cols-2"}>
                  <p className="text-gray-500 place-self-center text-[1rem] mr-auto whitespace-break-spaces leading-[0.02rem] not-italic">
                    {switchString(key)} :
                  </p>
                  <div className="mr-auto">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <p className="text-[#1B1A1F] text-[1rem] flex gap-2 text-left mr-auto">
                            {typeof value === "string" ? (value?.length > 20 ? value.slice(0, 20) + "..." : value) : ""}
                            {(key === "Counter name" || key === "Counter location" || key === "Clinic name") && (
                              <img src={check} alt="check" className="w-6 h-6" />
                            )}
                          </p>
                        </TooltipTrigger>
                        <TooltipContent>{typeof value === "string" ? value : ""}</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      <p className="text-[#49484C] font-semibold mt-8 text-lg">Attachments</p>
      <p className="text-[#49484C] mt-6 font-normal text-[1rem] relative pr-4 after:content-[''] after:absolute after:w-[calc(100%-8rem)] after:h-[1px] after:bg-gray-300 after:top-1/2 after:right-0">
        Counter Name
      </p>
      <div className="flex gap-5 mt-2">
        <div className="w-80 h-16 rounded-lg border border-[#E4E4E6] flex items-center justify-between px-2">
          <div className="flex gap-2">
            <img src={pdf} alt="pdf" className="w-10 h-10" />
            <div className="flex flex-col">
              <p className="text-[#324054] font-normal text-[.9rem]">{"<file name>"}</p>
              <p className="text-[#71839B] font-normal text-[.8rem]">313 KB. 31 Aug, 2022</p>
            </div>
          </div>
          <img src={download} alt="download" className="w-6 h-6 text-gray-500 cursor-pointer" />
        </div>
        <div className="w-80 h-16 rounded-lg border border-[#E4E4E6] flex items-center justify-between px-2">
          <div className="flex gap-2">
            <img src={pdf} alt="pdf" className="w-10 h-10" />
            <div className="flex flex-col">
              <p className="text-[#324054] font-normal text-[.9rem]">{"<file name>"}</p>
              <p className="text-[#71839B] font-normal text-[.8rem]">313 KB. 31 Aug, 2022</p>
            </div>
          </div>
          <img src={download} alt="download" className="w-6 h-6 text-gray-500 cursor-pointer" />
        </div>
      </div>
      <p className="text-[#49484C] mt-6 font-normal text-[1rem] relative pr-4 after:content-[''] after:absolute after:w-[calc(100%-9rem)] after:h-[1px] after:bg-gray-300 after:top-1/2 after:right-0">
        Counter Location
      </p>{" "}
      <div className="flex gap-5 mt-2">
        <div className="w-80 h-16 rounded-lg border border-[#E4E4E6] flex items-center justify-between px-2">
          <div className="flex gap-2">
            <img src={pdf} alt="pdf" className="w-10 h-10" />
            <div className="flex flex-col">
              <p className="text-[#324054] font-normal text-[.9rem]">{"<file name>"}</p>
              <p className="text-[#71839B] font-normal text-[.8rem]">313 KB. 31 Aug, 2022</p>
            </div>
          </div>
          <img src={download} alt="download" className="w-6 h-6 text-gray-500 cursor-pointer" />
        </div>
        <div className="w-80 h-16 rounded-lg border border-[#E4E4E6] flex items-center justify-between px-2">
          <div className="flex gap-2">
            <img src={pdf} alt="pdf" className="w-10 h-10" />
            <div className="flex flex-col">
              <p className="text-[#324054] font-normal text-[.9rem]">{"<file name>"}</p>
              <p className="text-[#71839B] font-normal text-[.8rem]">313 KB. 31 Aug, 2022</p>
            </div>
          </div>
          <img src={download} alt="download" className="w-6 h-6 text-gray-500 cursor-pointer" />
        </div>
      </div>
      <p className="text-[#49484C] mt-6 font-normal text-[1rem] relative pr-4 after:content-[''] after:absolute after:w-[calc(100%-7rem)] after:h-[1px] after:bg-gray-300 after:top-1/2 after:right-0">
        Clinic Name
      </p>{" "}
      <div className="flex gap-5 mt-2">
        <div className="w-80 h-16 rounded-lg border border-[#E4E4E6] flex items-center justify-between px-2">
          <div className="flex gap-2">
            <img src={pdf} alt="pdf" className="w-10 h-10" />
            <div className="flex flex-col">
              <p className="text-[#324054] font-normal text-[.9rem]">{"<file name>"}</p>
              <p className="text-[#71839B] font-normal text-[.8rem]">313 KB. 31 Aug, 2022</p>
            </div>
          </div>
          <img src={download} alt="download" className="w-6 h-6 text-gray-500 cursor-pointer" />
        </div>
        <div className="w-80 h-16 rounded-lg border border-[#E4E4E6] flex items-center justify-between px-2">
          <div className="flex gap-2">
            <img src={pdf} alt="pdf" className="w-10 h-10" />
            <div className="flex flex-col">
              <p className="text-[#324054] font-normal text-[.9rem]">{"<file name>"}</p>
              <p className="text-[#71839B] font-normal text-[.8rem]">313 KB. 31 Aug, 2022</p>
            </div>
          </div>
          <img src={download} alt="download" className="w-6 h-6 text-gray-500 cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default ORD1;
