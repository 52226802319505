import { Button } from "src/components";

const tableData = [
  {
    itemName: "Item Name",
    invoiceDescription: "Cortel H 40 Tablet 15 Tab",
    masterDescription: "Cortel H 40 Tablet 15 Tab",
    button: <button className="px-2 py-1 bg-gray-200 rounded-md">↔</button>,
  },
  {
    itemName: "Item Name",
    invoiceDescription: "Cortel H 40 Tablet 15 Tab",
    masterDescription: "Cortel H 40 Tablet 15 Tab",
    button: <button className="px-2 py-1 bg-gray-200 rounded-md">↔</button>,
  },
  {
    itemName: "Item Name",
    invoiceDescription: "Cortel H 40 Tablet 15 Tab",
    masterDescription: "Cortel H 40 Tablet 15 Tab",
    button: <button className="px-2 py-1 bg-gray-200 rounded-md">↔</button>,
  },
  {
    itemName: "Item Name",
    invoiceDescription: "Cortel H 40 Tablet 15 Tab",
    masterDescription: "Cortel H 40 Tablet 15 Tab",
    button: <button className="px-2 py-1 bg-gray-200 rounded-md">↔</button>,
  },
  {
    itemName: "Item Name",
    invoiceDescription: "Cortel H 40 Tablet 15 Tab",
    masterDescription: "Cortel H 40 Tablet 15 Tab",
    button: <button className="px-2 py-1 bg-gray-200 rounded-md">↔</button>,
  },
  {
    itemName: "Item Name",
    invoiceDescription: "Cortel H 40 Tablet 15 Tab",
    masterDescription: "Cortel H 40 Tablet 15 Tab",
    button: <button className="px-2 py-1 bg-gray-200 rounded-md">↔</button>,
  },
  {
    itemName: "Item Name",
    invoiceDescription: "Cortel H 40 Tablet 15 Tab",
    masterDescription: "Cortel H 40 Tablet 15 Tab",
    button: <button className="px-2 py-1 bg-gray-200 rounded-md">↔</button>,
  },
  {
    itemName: "Item Name",
    invoiceDescription: "Cortel H 40 Tablet 15 Tab",
    masterDescription: "Cortel H 40 Tablet 15 Tab",
    button: <button className="px-2 py-1 bg-gray-200 rounded-md">↔</button>,
  },
  {
    itemName: "Item Name",
    invoiceDescription: "Cortel H 40 Tablet 15 Tab",
    masterDescription: "Cortel H 40 Tablet 15 Tab",
    button: <button className="px-2 py-1 bg-gray-200 rounded-md">↔</button>,
  },
];

const InvoiceProductMapping = () => {
  return (
    <div>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "left",
          border: "1px solid #ccc",
        }}
        className="mt-10"
      >
        <thead>
          <tr className="text-sm bg-[#F1F1F1]">
            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}></th>
            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>As per Master</th>
            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Generated Invoice</th>
            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}></th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index} className="w-full">
              <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }} className="w-[32%] text-gray-500">
                <input type="checkbox" /> <span className="ml-2 text-sm font-normal">{row.itemName}</span>
              </td>
              <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }} className="w-[32%] text-sm">
                {row.invoiceDescription}
              </td>
              <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }} className="w-[32%] text-sm">
                {row.masterDescription}
              </td>
              <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }} className="w-[6%]">
                {row.button}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end mt-14 gap-x-2">
        <Button className="font-normal bg-gray-200 border border-gray-300">Escalate to Master</Button>
        <Button className="font-normal text-white bg-primary">Confirm Invoice</Button>
      </div>
    </div>
  );
};

export default InvoiceProductMapping;
