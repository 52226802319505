import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { Input } from "src/components";
import Breadcrumb from "src/components/ui/breadcrumb.component";
import InvoiceEntry from "./invoice-entry.component";
// import InvoiceApproval from "../invoice-master/invoice-approval.component";
import InvoiceLayout from "../invoice-master/invoice-layout.component";
// import { InvoiceEntryScreen } from "../invoice-details/InvoiceEntryScreen";

const tabs = ["Entry", "Approval", "Completed"];

const InvoiceHomepage = () => {
  return (
    <InvoiceLayout>
      <header className="flex flex-col justify-between py-5 mx-auto">
        <div className="flex justify-between px-[3.75rem]">
          <div>
            <Breadcrumb
              currentActive="invoice"
              dataOrigin={[
                { name: "Transactions", path: "transactions" },
                { name: "Invoices", path: "invoice" },
              ]}
            />
            <h1 className="text-[#49484C] font-bold text-[2rem]">Invoices</h1>
            {/* <p className="font-medium text-base text-[#929194]">Invoices</p> */}
          </div>

          <div className="flex gap-2 items-center">
            <Input placeholder="Search" className="px-3 h-[32px] w-[180px] rounded-lg " showIcon={true} />
          </div>
        </div>
        <Tab.Group>
          <Tab.List className="relative px-[3.75rem] mb-[1.16rem] mt-6 border-b-[1px] border-b-[#E9E9E9] shadow-md">
            {tabs.map((item: string) => (
              <Tab key={item} as={Fragment}>
                {({ selected }: { selected: boolean }) => (
                  <div
                    className={`relative inline-block cursor-pointer -bottom-[1px] px-4 py-3 font-semibold text-sm  border-b-2  not-italic  hover:border-b-[#586AF5] hover:text-[#586af5] focus:outline-none ${
                      selected ? "text-[#586af5] border-b-[#586AF5]" : "text-[rgba(0,0,0,0.4)] border-b-transparent"
                    }`}
                  >
                    {item}
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel className="px-[3.75rem]">{<InvoiceEntry />}</Tab.Panel>
            <Tab.Panel className="px-[3.75rem]">
              {/* <InvoiceApproval /> */}
            </Tab.Panel>
            <Tab.Panel className="px-[3.75rem]">
              {/* <InvoiceEntryScreen /> */}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </header>
    </InvoiceLayout>
  );
};

export default InvoiceHomepage;
