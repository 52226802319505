import { Button } from "src/components";

const tableData = [
  { label: "Invoice No.", master: "#123-456-789", invoice: "#123-456-789" },
  { label: "Invoice Date", master: "6 August 2024", invoice: "" },
  { label: "Reference ID", master: "#123-456-789", invoice: "#123-456-789" },
  { label: "Doctor name", master: "Indian Medical Practitioners Co-Op Pharmacy", invoice: "Indian Medical Practitioners Co-Op Pharmacy" },
  { label: "Link Type", master: "DRDS", invoice: "DRDS" },
  { label: "Stockist", master: "Gujaraja", invoice: "Gujaraja" },
  { label: "Pharmacy name", master: "Dr. Shreevansh Kumar", invoice: "Dr. Shreevansh Kumar" },
  { label: "Address", master: "Sun Pharmaceuticals", invoice: "Sun Pharmaceuticals" },
  { label: "City", master: "-", invoice: "-" },
  { label: "MCI No.", master: "#123-456-789", invoice: "#123-456-789" },
  { label: "DL Number*", master: "#123-456-789", invoice: "#123-456-789" },
  { label: "GST No.*", master: "#123-456-789", invoice: "#123-456-789" },
  { label: "GST Status", master: "Cancelled", invoice: "" },
];

const InvoiceHeader = () => {
  return (
    <div>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "left",
          border: "1px solid #ccc",
        }}
        className="mt-10"
      >
        <thead>
          <tr className="text-sm bg-[#F1F1F1]">
            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}></th>
            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>As per Master</th>
            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Generated Invoice</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index} className="w-full">
              <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }} className="w-1/3 text-gray-500">
                <input type="checkbox" /> <span className="ml-2 text-sm font-normal">{row.label}</span>
              </td>
              <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }} className="w-1/3 text-sm">
                {row.master}
              </td>
              <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }} className="w-1/3 text-sm">
                {row.invoice}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end mt-14 gap-x-2">
        <Button className="font-normal bg-gray-200 border border-gray-300">Escalate to Master</Button>
        <Button className="font-normal text-white bg-primary">Confirm Invoice</Button>
      </div>
    </div>
  );
};

export default InvoiceHeader;
