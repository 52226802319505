import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { vitecompanyserviceurl } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

const companyserviceurl: AxiosInstance = axios.create({
  baseURL: vitecompanyserviceurl + "/api/v1/oms",
});

const companyserviceurlevent: AxiosInstance = axios.create({
  baseURL: vitecompanyserviceurl,
});

companyserviceurl.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
companyserviceurlevent.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));

export const fetchInvoicesData = () => {
  return companyserviceurl.get(`/stockist_attachment/all`, {
    headers: {
      company_code: 618,
    },
  });
};

export const fetchPriorityInvoicesData = () => {
  return companyserviceurl.get(`/stockist_attachment/all?is_prioritised=true`, {
    headers: {
      company_code: 618,
    },
  });
};

export const fetchEscalatedInvoicesData = () => {
  return companyserviceurl.get(`/stockist_attachment/all?is_escalated=true`, {
    headers: {
      company_code: 618,
    },
  });
};

export const getInvoiceDetails = (stockist_invoice_attachment_id: string, products: boolean) => {
  return companyserviceurl.get(
    `/stockist_attachment/details?stockist_invoice_attachment_id=${stockist_invoice_attachment_id}&invoice_id=&products=${products}`,
    {
      headers: {
        company_code: 618,
      },
    }
  );
};

export const downloadInvoice = (file_path: string) => {
  return companyserviceurl.get(`/stockist_attachment/get-file?file_key=${file_path}`, {
    headers: {
      company_code: 618,
    },
  });
};

export const uploadInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/invoice/add`, payload);
};

export const updateInvoice = (payload: any) => {
  return companyserviceurlevent.post(`event/invoice/product-add`, payload);
};

export const rejectInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/stockist_attachment/reject`, payload);
};

export const getRejectionCategory = () => {
  return companyserviceurlevent.get(`api/v1/oms/stockist_attachment/reject-categories`);
};

export const getAssigneeList = () => {
  return companyserviceurl.get("/stockist_attachment/invoice-assignees");
};

export const reAssignInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/stockist_attachment/reassign`, payload);
};

export const escalateInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/stockist_attachment/escalate`, payload);
};

export const setPriorityInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/stockist_attachment/set-priority`, payload);
};
