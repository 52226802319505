import SmartForm from "src/container/smart-form.component";
import { Button, Input } from "src/components";
import * as yup from "yup";
import InvoiceDetailCard from "./invoice-detail.component";
import SubTable from "../counter-details/sub-table.component";
import { flattenObject } from "src/constants/functions";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import downArrow from "src/assets/down-arrow.svg";
import InvoiceLayout from "../invoice-master/invoice-layout.component";
import { useParams } from "react-router-dom";
import { getInvoiceDetails, updateInvoice } from "src/services/invoice-api";
import Breadcrumb from "src/components/ui/breadcrumb.component";
import { getInvoiceDetailsInfo } from "../invoice-master/invoice-helper";
import Loader from "src/components/ui/loader.component";
import { toast } from "src/hooks/useToast";

const schema = yup.object({
  itemCode: yup.string().required("Item Code is required"),
  itemName: yup.string().required("Item Name is required"),
  mrp: yup.number().required("MRP is required"),
  price: yup.number().required("Price is required"),
  schemeType: yup.string().required("Scheme Type is required"),
  freeQty: yup.number().required("Free Qty is required"),
  discountPercentage: yup.number().required("Discount % is required"),
  discountAmount: yup.number().required("Discount Amount is required"),
  batchNo: yup.string().required("Batch No. is required"),
});

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("item_description", {
    header: "Item Name",
    cell: (info: any) => info.row.original.item_description || "-",
  }),
  columnHelper.accessor("item_code", {
    header: "Item Code",
    cell: (info: any) => info.row.original.item_code || "-",
  }),
  columnHelper.accessor("batch_no", {
    header: "Batch No.",
    cell: (info: any) => info.row.original.batch_no || "-",
  }),
  columnHelper.accessor("inv_rate", {
    header: "Inv. Rate",
    cell: (info: any) => info.row.original.inv_rate || "-",
  }),
  columnHelper.accessor("inv_mrp", {
    header: "Inv. MRP",
    cell: (info: any) => info.row.original.inv_mrp || "-",
  }),
  columnHelper.accessor("quantity", {
    header: "Qty.",
    cell: (info: any) => info.row.original.quantity,
  }),
  columnHelper.accessor("price_identifier_id", {
    header: "Discount Defined By",
    cell: (info: any) => info.row.original.price_identifier_id || "-",
  }),
  columnHelper.accessor("gross_amt", {
    header: "Gross Amt.",
    cell: (info: any) => info.row.original.gross_amt || "-",
  }),
  columnHelper.accessor("gst", {
    header: "GST %",
    cell: (info: any) => info.row.original.gst || "-",
  }),
];

export const InvoiceEntryScreen = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [invoiceData, setInvoiceData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [addedProducts, setAddedProducts] = useState<any[]>([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [castDiscountPercent, setCastDiscountPercent] = useState<number>(0);
  const [castDiscountAmount, setCastDiscountAmount] = useState<number>(0);
  const subtotal = invoiceData?.invoice?.invoiceProducts?.reduce((acc: any, curr: any) => acc + Number(curr?.inv_mrp), 0);

  const handleCashDiscountPercentChange = (e: number) => {
    if (e > 0) {
      setCastDiscountAmount((e * subtotal) / 100);
    } else {
      setCastDiscountAmount(0);
    }
  };

  const handleCashDiscountAmountChange = (e: number) => {
    if (e > 0) {
      setCastDiscountPercent((e / subtotal) * 100);
    } else {
      setCastDiscountPercent(0);
    }
  };

  let subrow: any[] = [];
  let subrow2: any[] = [];

  addedProducts?.forEach((element: any) => {
    subrow.push(flattenObject(element));
  });
  addedProducts?.forEach((element: any) => {
    subrow2.push(flattenObject(element));
  });

  const { stockist_invoice_attachment_id } = useParams();

  const fetchInvoiceDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getInvoiceDetails(stockist_invoice_attachment_id as string, false);
      setInvoiceData(response?.data?.data);
      setAddedProducts(response?.data?.data?.invoice?.invoiceProducts);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleFormSubmit = async (data: any) => {
    setIsLoading2(true);
    const payload = {
      data: {
        invoice_id: Number(invoiceData?.invoice?.invoice_id),
        item_description: String(data?.itemName),
        item_code: String(data?.itemCode),
        quantity: Number(data?.freeQty),
        gst: "18",
        price_identifier_id: Number(data?.schemeType),
        batch_no: data?.batchNo as string,
        inv_rate: String(data?.price),
        inv_mrp: String(data?.mrp),
        discount_amt: String(data?.discountAmount),
        counter_code: invoiceData?.counter_code,
      },
      primary_key: invoiceData?.invoice?.invoice_id,
      event_type: "INVOICE_PRODUCT_ADD",
    };
    try {
      const res = await updateInvoice(payload);
      setTimeout(async () => {
        const response = await getInvoiceDetails(stockist_invoice_attachment_id as string, true);
        setAddedProducts(response?.data?.data?.invoice?.invoiceProducts);
        setIsLoading2(false);
      }, 1500);
      toast({ description: "Product added successfully", variant: "default" });
      console.log(res);
    } catch (error) {
      console.log(error, "error");
      toast({ description: "Product add failed", variant: "destructive" });
    } finally {
      setIsLoading2(false);
    }
  };

  const handleAddItem = () => {
    setOpenForm(true);
  };

  useEffect(() => {
    fetchInvoiceDetails();
  }, []);

  if (isLoading || isLoading2) {
    return <Loader />;
  }

  return (
    <InvoiceLayout>
      <div className="flex justify-between px-[3.75rem] mt-6 border-b-[1px] border-b-[#E9E9E9] shadow-md pb-5">
        <div className="">
          <Breadcrumb
            currentActive="invoice"
            dataOrigin={[
              { name: "Transactions", path: "transactions" },
              { name: "Invoices", path: "invoice" },
            ]}
          />
          <h1 className="text-[#49484C] font-bold text-[2rem]">Invoices</h1>
          {/* <p className="font-medium text-base text-[#929194]">Invoices</p> */}
        </div>

        <div className="flex gap-2 items-center">
          <Input placeholder="Search" className="px-3 h-[32px] w-[180px] rounded-lg " showIcon={true} />
        </div>
      </div>
      <div className="px-[3.75rem] mx-auto mt-10">
        <ul className="mt-5">
          <li className="my-5 mb-10 rounded-lg ">
            <InvoiceDetailCard
              isExpandedInitial={true}
              isExpandable={false}
              status={invoiceData?.fileStatusHdr?.fuid_status_desc}
              checkDataType={false}
              data={getInvoiceDetailsInfo(invoiceData)}
              viewDetails={false}
              invoiceData={invoiceData}
              handleAddItem={handleAddItem}
            />
          </li>
        </ul>
        {openForm && (
          <div className="p-5 mb-10 border-2 rounded-lg">
            <div className="flex items-center justify-between">
              <p className="mb-2 text-lg font-semibold">Invoice Details</p>
              <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
                <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
              </Button>
            </div>
            <p className="mb-5 text-sm text-gray-500">Item #1</p>
            <div
              className={`overflow-hidden transition-all duration-500 ease-in-out ${isExpanded ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"}`}
              style={{ transitionProperty: "max-height, opacity" }}
            >
              <SmartForm onFormSubmit={handleFormSubmit} schema={schema} defaultValues={{}}>
                <div className="grid grid-cols-4 gap-4">
                  <SmartForm.Input
                    className="w-full"
                    inputClassName="w-full"
                    type="text"
                    label="Item Code"
                    showRequiredMark={false}
                    placeholder=" "
                    fieldName="itemCode"
                  />
                  <SmartForm.Input
                    className="w-full"
                    inputClassName="w-full"
                    type="text"
                    placeholder=" "
                    fieldName="itemName"
                    label="Item Name"
                    showRequiredMark={false}
                  />
                  <SmartForm.Input
                    className="w-full"
                    inputClassName="w-full"
                    type="text"
                    placeholder=" "
                    fieldName="mrp"
                    label="MRP"
                    showRequiredMark={false}
                  />
                  <SmartForm.Input
                    className="w-full"
                    inputClassName="w-full"
                    type="text"
                    placeholder=" "
                    fieldName="price"
                    label="Price"
                    showRequiredMark={false}
                  />
                  <SmartForm.Input
                    className="w-full"
                    inputClassName="w-full"
                    type="text"
                    placeholder=" "
                    fieldName="schemeType"
                    label="Scheme Type"
                    showRequiredMark={false}
                  />
                  <SmartForm.Input
                    className="w-full"
                    inputClassName="w-full"
                    type="text"
                    label="Free Qty"
                    showRequiredMark={false}
                    placeholder=" "
                    fieldName="freeQty"
                  />
                  <SmartForm.Input
                    className="w-full"
                    inputClassName="w-full"
                    type="text"
                    label="Discount %"
                    showRequiredMark={false}
                    placeholder=" "
                    fieldName="discountPercentage"
                  />
                  <SmartForm.Input
                    className="w-full"
                    inputClassName="w-full"
                    type="text"
                    label="Discount Amount"
                    showRequiredMark={false}
                    placeholder=" "
                    fieldName="discountAmount"
                  />
                  <SmartForm.Input
                    className="w-full"
                    inputClassName="w-full"
                    type="text"
                    label="Batch No."
                    showRequiredMark={false}
                    placeholder=" "
                    fieldName="batchNo"
                  />
                </div>
                <div className="flex justify-end mt-5 gap-x-2">
                  <Button className="font-normal text-gray-700 bg-[#F1F1F1] h-9 border-2 border-gray-200">Add another item</Button>

                  <Button className="h-9 text-white font-normal bg-[#8051FF] shadow-sm" type="submit">
                    Add Product
                  </Button>
                </div>
              </SmartForm>
            </div>
          </div>
        )}
        <div className="flex flex-col mt-5 gap-x-5 xl:flex-row">
          <div className="w-full p-8 border rounded-lg xl:w-9/12 flex flex-col gap-4">
            <SubTable title="Items Added" subtitle="Review and manage all items added" showheader={true} columns={columns} data={subrow} />
            <SubTable title="Unknown Order" subtitle="Review and manage all items added" showheader={true} columns={columns} data={subrow2} />
          </div>
          <div className="w-full p-8 mt-10 border rounded-lg xl:w-3/12 xl:mt-0">
            <p className="mb-2 text-lg font-semibold">Calculations</p>
            <div className="flex flex-col pb-10 gap-y-1">
              <p className="flex justify-between py-2 text-sm">
                Subtotal <span className="">₹{subtotal}</span>
              </p>
              <p className="flex py-2 text-sm justify-between">
                <span className="mr-2">Cash Discount %</span>
                <div className="">
                  <Input
                    enableArrows={false}
                    showIcon={false}
                    type="number"
                    placeholder="0"
                    value={castDiscountPercent.toFixed(2)}
                    onChange={(e: any) => handleCashDiscountPercentChange(e?.target?.value)}
                    className="py-1 text-right w-24 border rounded-lg border-gray-300"
                  />{" "}
                </div>
              </p>
              <p className="flex justify-between py-2 text-sm">
                <span className="mr-2">Cash Discount Amount</span>
                <div className="">
                  <Input
                    enableArrows={false}
                    showIcon={false}
                    type="number"
                    placeholder="0"
                    value={castDiscountAmount.toFixed(2)}
                    onChange={(e: any) => handleCashDiscountAmountChange(e?.target?.value)}
                    className="py-1 text-right w-24 border rounded-lg border-gray-300"
                  />{" "}
                </div>
              </p>
              <p className="flex justify-between py-2 text-sm">
                Total (as calculated) <span className="font-semibold">₹{(subtotal - castDiscountAmount).toFixed(2)}</span>
              </p>
              <p className="flex justify-between py-2 text-sm">
                <span className="mr-2">Net Inv. Amt.</span>
                <div className="">
                  <Input
                    enableArrows={false}
                    showIcon={false}
                    type="number"
                    placeholder=""
                    value={subtotal - castDiscountAmount}
                    className="py-1 text-right w-24 border rounded-lg border-gray-300"
                  />{" "}
                </div>
              </p>
              <Button className="w-full h-9 mt-5 font-normal text-white bg-[#8051FF] shadow-sm">Confirm Invoice</Button>
            </div>
          </div>
        </div>
      </div>
    </InvoiceLayout>
  );
};
