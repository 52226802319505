import { Tab } from "@headlessui/react";
import { Pencil } from "lucide-react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PurpleLeftArrowIcon from "src/assets/purple-left-arrow.svg";
import { Badge, Button } from "src/components";
import VersioningCrate from "src/components/Versioning/versioningModal.component";
import { ApiBlocker } from "src/constants/global-const";
import DeleteModal from "src/container/DeleteModal";
import TableActiveInactiveDropdown from "src/container/table-active-inactive-dropdown.component";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";
import { versioningActions } from "src/context/versioning-slice";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { useToast } from "src/hooks/useToast";
import { activeInactiveStockist, queryStockist } from "src/services/stockist-api";
import Book from "../../assets/book.png";
import StockistLayout from "../stockist-master/stockist-layout.component";
import CompanySection from "./company-section.component";
import DetailsSection from "./details-section.component";

const tabs = ["Details", "Companies"];

const StockistDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showOverlay, setshowOverlay] = useState(false);
  const { toast } = useToast();
  const [_, setIsLoading] = useState(true);
  const [showRefNumber, setShowRefNumber] = useState<any>(false);
  const [VersioningModal, setVersioningModal] = useState(false);

  const ref = useOutsideClick(() => {
    setVersioningModal((prev) => !prev);
    dispatch(versioningActions.setProductVersioning([]));
  });

  const { selectedStockistData } = useSelector((state: RootState) => state.stockist);

  const fetchDetails = useCallback(async () => {
    if (!window.sessionStorage.getItem("stockistMasterId")) {
      navigate("/masters/stockists");
    }

    const queryParams = `?stockist_id=${window.sessionStorage.getItem("stockistMasterId")}`;
    let response: any = null;
    response = await queryStockist(`/${queryParams.toString()}&company=true&cluster=true&perPage=0&page=0`);
    if (response?.status !== 200) throw new Error();
    // response = await queryStockist(`/${queryParams.toString()}&company=true&cluster=true`);
    console.log("response?.data?.data?.data[0]", response?.data?.data?.data[0]);
    setIsLoading(false);
    dispatch(stockistActions.setSelectedStockistData(response?.data?.data?.data[0]));
  }, [dispatch]);

  const handleInactive = async (referenceDetails: any) => {
    try {
      console.log("selectedStockistData", selectedStockistData);
      const stockistFormData = selectedStockistData;
      const status = selectedStockistData?.active_status === "Y" ? "N" : "Y";

      console.log("status", status);
      const body: any = {
        owner_name: stockistFormData?.owner_name,
        owner_mobile_no: stockistFormData?.owner_mobile_no,
        medvol_code: stockistFormData?.medvol_code,
        firm_name: stockistFormData?.firm_name,
        manager_name: stockistFormData?.manager_name,
        manager_mobile_no: stockistFormData?.manager_mobile_no,
        address: stockistFormData?.address,
        gst: stockistFormData?.gst,
        gst_available: stockistFormData?.gst_available?.id,
        gst_status: stockistFormData?.gst_status?.id,
        drug_licence: stockistFormData?.drug_licence,
        food_licence: stockistFormData?.food_licence,
        email: stockistFormData?.email,
        group_name: stockistFormData?.group_name,
        u_code: selectedStockistData?.u_code,
        city: stockistFormData?.city?.city_code, // Assuming city is represented by an ID
        elnv_qualification: stockistFormData?.elnv_qualification,
        elnv_invoice: stockistFormData?.elnv_qualification === "Yes" ? stockistFormData?.elnv_invoice?.einvack_type_code : 0,
        stockist_id: stockistFormData?.stockist_id,
        staff_position_id: stockistFormData?.medvol_field?.staff_position_id,
        medvol_field_uid: stockistFormData?.medvol_field?.uid,
        reference_number: referenceDetails?.reference_number,
      };
      body.active_status = status;
      console.log("body", body);
      console.log("{ ...selectedStockistData }, selectedStockistData?.stockist", { ...selectedStockistData }, selectedStockistData?.stockist);
      const res = await activeInactiveStockist({ ...body }, selectedStockistData?.stockist_id);
      if (res?.status === 200) {
        toast({
          description: "Updated Successfully",
        });
      } else {
        throw new Error();
      }
      setTimeout(() => {
        fetchDetails();
      }, 1500);
      // await dispatch(stockistActions.updateStockistStatus({ stockist_code: selectedStockistData?.stockist_code, active_status: status }));
    } catch (error: any) {
      console.log("err", error);
      toast({
        description: error?.message || "Unable to update",
        variant: "destructive",
      });
    } finally {
      setShowRefNumber(false);
    }
  };

  const handleStockistToggle = () => {
    const status = selectedStockistData?.active_status === "Y" ? "N" : "Y";

    if (status === "N" && selectedStockistData?.stockist_company_lnk?.some((obj: any) => obj?.active_status === "Y")) {
      toast({
        description: "Deactivate all linked companies to inactivate the stockist.",
        variant: "destructive",
      });

      return;
    } else {
      setShowRefNumber(true);
    }
  };

  useEffect(() => {
    console.log("ApiBlocker.isApiBlocked", ApiBlocker.isApiBlocked);
    setshowOverlay(ApiBlocker.isEventBlocked);
  }, [ApiBlocker.isEventBlocked]);

  // if (isLoading) return <Loader />;

  return (
    <>
      {location.pathname.toLocaleLowerCase().includes("details") && showOverlay ? (
        <div
          className="py-0.5"
          style={{
            position: "absolute",
            top: 128,
            left: 0,
            width: "100%",

            backgroundColor: "rgba(117, 80, 250, 0.3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // zIndex: 1000,
          }}
        >
          <div className="h-8 text-center">
            <p style={{ color: "#7450FA", fontSize: "24px", fontWeight: "bold", textShadow: "1px 1px 2px #FFFFFF" }}>
              <p style={{ color: "#7450FA", fontSize: "16px", fontWeight: "bold", textShadow: "1px 1px 2px #FFFFFF", paddingTop: 3 }}>
                We regret to inform you, The event has been "blocked" due to too many rigorous events in Queue.
              </p>
            </p>
          </div>
        </div>
      ) : null}
      <StockistLayout>
        <div
          onClick={() => {
            setVersioningModal((prev) => !prev);
          }}
          className="absolute right-0 h-auto p-1 py-3 border-b-2 border-l-2 cursor-pointer"
        >
          <img onClick={() => {}} src={Book} alt="React Logo" className="h-10 w-14" />
        </div>
        {VersioningModal && (
          <div ref={ref} className="px-3">
            <VersioningCrate type="Stockist" />
          </div>
        )}
        <section className="w-full mt-6">
          <header className="px-[3.75rem] w-full flex justify-between items-center">
            <section>
              <button
                onClick={() => navigate("/masters/stockists")}
                className="inline-flex mt-[1.16rem] gap-3 items-center text-[#7450FA] cursor-pointer text-base font-semibold"
              >
                <img src={PurpleLeftArrowIcon} />
                Back to Stockists
              </button>
              <div className="flex flex-wrap items-center justify-between">
                <p className="text-[2rem] line-height-normal font-bold text-[#323136] not-italic">{selectedStockistData?.firm_name}</p>
              </div>
              <div className="flex flex-wrap gap-4 mt-3">
                <Badge variant={selectedStockistData?.active_status === "Y" ? "success" : "error"}>
                  {selectedStockistData?.active_status === "Y" ? "Active" : "Inactive"}
                </Badge>
                <Badge variant="info">{selectedStockistData?.medvol_code}</Badge>
              </div>
            </section>
            <div className="flex items-center gap-2">
              <TableActiveInactiveDropdown
                onChanged={() => handleStockistToggle()}
                // defaultValue={selectedStockistData?.active_status === "Y" ? "active" : "inactive"}
                defaultValue={selectedStockistData?.active_status === "Y" ? "active" : "inactive"}
              />
              <Button
                className="gap-1 border"
                onClickCapture={() => {
                  dispatch(stockistActions.setStockistFullFormData(selectedStockistData));
                  dispatch(stockistActions.setShowStockistMainForm(true));
                  dispatch(stockistActions.setMainFormEditMode(true));
                }}
              >
                <Pencil width={14} height={14} /> Edit Details
              </Button>
            </div>
          </header>

          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="border-b-[1px] border-b-[rgba(0, 0, 0, 0.10)] mb-[1.16rem] px-[3.75rem] mt-6">
              {tabs.map((item: string) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }: { selected: boolean }) => (
                    <div
                      className={`relative inline-block cursor-pointer -bottom-[1px] px-4 py-3 font-semibold text-sm  border-b-2  not-italic  hover:border-b-[#586AF5] hover:text-[#586af5] focus:outline-none ${
                        selected ? "text-[#586af5] border-b-[#586AF5]" : "text-[rgba(0,0,0,0.4)] border-b-transparent"
                      }`}
                    >
                      {item}
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              <Tab.Panel className="px-[3.75rem]">
                <DetailsSection />
              </Tab.Panel>
              <Tab.Panel className="px-[3.75rem]">
                <CompanySection />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </section>
      </StockistLayout>

      <DeleteModal value={showRefNumber} setValue={setShowRefNumber} onFormSubmit={handleInactive} />
    </>
  );
};

export default StockistDetails;
