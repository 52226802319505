// export const api = "http://13.126.55.78:3001";

import axios from "axios";
import { getCookieValue } from "./functions";
import { employeeEvent, geographyEvent } from "src/utils/eventEmitter";

export const api = import.meta.env.VITE_API_URL;
export const isProd = import.meta.env.VITE_IS_PROD;
export const accessControlApi = import.meta.env.VITE_ACCESS_CONTROL_API;
export const emailMobileCheck = import.meta.env.VITE_EMAIL_MOBILE_CHECK;
export const omsBaseUrl = import.meta.env.VITE_OMS_BASE_URL;
export const omsLatestUrl = import.meta.env.VITE_OMS_URL_LATEST;
export const vitecompanyserviceurl = import.meta.env.VITE_COMPANY_SERVICE_URL;
export const medvolApiUrl = import.meta.env.VITE_MEDVOL_API_URL;

export let isApiBlocked = false;

export const apiValidations = import.meta.env.VITE_API_VALIDATIONS;

export const logout = (showWarning: Boolean = true) => {
  const clientId = import.meta.env.VITE_CLIENT_ID;
  const redirectUri = encodeURIComponent(window.location.origin);

  const queryParams = new URLSearchParams({
    response_type: "token",
    client_id: clientId,
    redirect_uri: redirectUri,
  });

  let logoutResponse = true;

  if (showWarning) {
    logoutResponse = confirm("You are logged out. Please login again to continue.");
    if (!logoutResponse) return;
  }

  const logoutUrl = `${import.meta.env.VITE_LOGOUT_URL}?${queryParams.toString()}`;

  window.location.href = logoutUrl;
};

/**
 * Class representing an API blocker.
 */
const apiCall = async (axios: any, url: string, data?: any): Promise<any> => {
  try {
    // const type = window.location.origin.includes("ops.stage") ? "/stage" : "/dev";

    const response = await axios.get(api + url, {
      headers: {
        Authorization: JSON.stringify({ authorization: getCookieValue("idToken"), refreshToken: getCookieValue("refreshToken") }),
      },
      data: data,
    });
    return response.data;
  } catch (error) {
    console.error("API call failed", error);
    return Promise.reject(error);
  }
};

export let toastStatic = { value: false, message: "" };

export const changeShowErrorToast = (value: boolean, message: string) => {
  toastStatic = { value, message };
};

export let bannerStatic = { isEventPending: false, isEventBlocked: false };

export const changeApiBlocker = (isEventPending: boolean, isEventBlocked: boolean) => {
  bannerStatic = { isEventPending, isEventBlocked };
};

export class ApiBlocker {
  /**
   * Indicates whether the API is blocked or not.
   */
  static isApiBlocked = false;
  static isEventBlocked = false;
  static isEventPending = false;

  /**
   * Interceptor function to block API requests based on the status condition.
   * @param axios - The axios instance.
   * @returns The axios interceptor.
   */

  static eventChecked = async (streamId?: string) => {
    try {
      changeApiBlocker(false, false);
      changeShowErrorToast(false, "");

      if (!streamId) return;
      const response = await apiCall(axios, `/api/v1/common/check-event-status?streamId=${streamId}&consumerName=V2`);

      changeApiBlocker(response?.isEventPending, response?.isEventBlocked);
      ApiBlocker.isEventBlocked = response?.isEventBlocked;
      ApiBlocker.isEventPending = response?.isEventPending;
      geographyEvent.emit("eventBlocked", response?.isEventBlocked);
      geographyEvent.emit("eventPending", response?.isEventPending);
      employeeEvent.emit("eventBlocked", response?.isEventBlocked);
      employeeEvent.emit("eventPending", response?.isEventPending);
      if (response?.isEventBlocked || response?.isEventPending) {
        const message = response.isEventBlocked ? "Event Blocked. Please try after some time" : "Event is pending. Please wait";
        changeShowErrorToast(true, message);
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  static isApiBlockedFn(axios: any) {
    return axios.interceptors.request.use(
      (config: any) => {
        // Check the status condition before allowing the request
        if ((config.method === "post" || config.method === "put" || config.method === "patch") && ApiBlocker.isApiBlocked) {
          // If the condition is not met, you can reject the request
          return Promise.reject(new Error("Post request is blocked."));
        }

        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }
}

// export const isApiBlockedFn = (axios: any, shouldAllowPostRequest: boolean) => {
//   axios.interceptors.request.use(
//     (config: any) => {
//       // Check the status condition before allowing the request
//       if ((config.method === "post" || config.method === "put" || config.method === "patch") && !shouldAllowPostRequest) {
//         // If the condition is not met, you can reject the request
//         return Promise.reject(new Error("Post request is blocked."));
//       }

//       return config;
//     },
//     (error: any) => {
//       return Promise.reject(error);
//     }
//   );
// };
