import { Transition } from "@headlessui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterIcon from "src/assets/filter.svg";
import { Button, Input, Table } from "src/components";
import Breadcrumb from "src/components/ui/breadcrumb.component";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { useToast } from "src/hooks/useToast";
import { usePagination } from "src/hooks/usePagination";
import { getAllCompanyDetails } from "src/services/company-api";
import { getCityDetails } from "src/services/region-api";
import { getAllStateDetails } from "src/services/product-api";
import { getDistrictDetails } from "src/services/region-api";
import { queryStockistDetails } from "src/services/stockist-api";
import { appendClientParams, appendParams } from "src/utils/utils";
import StockistsFilter from "./stockist-filter.component";
import StockistLayout from "./stockist-layout.component";

const columnHelper = createColumnHelper();

const StatusList = [
  { key: "Y", value: "Yes" },
  { key: "N", value: "No" },
];

const StockistTypeList = [
  { id: 2, stockist_type: "Regular" },
  { id: 1, stockist_type: "Skilled" },
  { id: 3, stockist_type: "Special" },
];

const StockistMaster = () => {
  const { companyList, cityList, districtList, stateList, statusList, stockistTypeList, filterCount } = useSelector(
    (state: RootState) => state.stockist
  );
  const { filters } = useSelector((state: RootState) => state.stockist);
  // const { divisionList } = useSelector((state: RootState) => state.division);
  const [isLoading, setIsLoading] = useState(false);
  const [stockistData, setStockistData] = useState([]);
  const [isFiltersShown, setIsFiltersShown] = useState<boolean>(false);
  const isMount = useRef(false);
  const [totalRowCount, setTotalRowCount] = useState(10);
  // const [isFiltersShown, setIsFiltersShown] = useState<boolean>(false);

  const navigate = useNavigate();
  const { toast } = useToast();
  const { pagination, onPaginationChange, pageIndex, pageSize, resetPagination } = usePagination();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const dispatch = useDispatch();

  useEffect(() => {
    getAllCompanyDetails()
      .then((res) => {
        dispatch(stockistActions.setCompanyList(res?.data));
      })
      .catch((err) => {
        console.log(err);
      });
    getCityDetails().then((res) => {
      dispatch(stockistActions.setCityList(res?.data?.data));
    });
    getDistrictDetails()
      .then((res) => {
        dispatch(stockistActions.setDistrictList(res.data.data));
      })
      .catch((err) => console.log(err));
    getAllStateDetails()
      .then((res) => {
        dispatch(stockistActions.setStateList(res.data.data));
      })
      .catch((err) => console.log(err));

    dispatch(stockistActions.setStatusList(StatusList));
    dispatch(stockistActions.setStockistTypeList(StockistTypeList));
  }, []);

  const retrieveCounterHandler = async () => {
    try {
      setIsLoading(true);
      let backendParams = "?";
      let clientParams = "";

      const companyClientParams = appendClientParams(Array.isArray(filters?.company) ? filters.company : [], "c", "name", "code");
      const companyParams = appendParams(Array.isArray(filters?.company) ? filters.company : [], "company_code", "code");

      const cityParams = appendParams(Array.isArray(filters?.city) ? filters.city : [], "city_code", "city_code");
      const districtParams = appendParams(Array.isArray(filters?.district) ? filters.district : [], "district_id", "district_code");
      const stateParams = appendParams(Array.isArray(filters?.state) ? filters.state : [], "state_id", "state_code");
      const statusParams = appendParams(Array.isArray(filters?.status) ? filters.status : [], "active_status", "key");
      const stockistTypeParams = appendParams(Array.isArray(filters?.stockistType) ? filters.stockistType : [], "stockist_type", "stockist_type");
      const cityClientParams = appendClientParams(Array.isArray(filters?.city) ? filters.city : [], "rc", "city_name", "city_code");

      backendParams += [companyParams, cityParams, districtParams, stateParams, statusParams, stockistTypeParams].filter(Boolean).join("&");

      clientParams += [companyClientParams, cityClientParams].filter(Boolean).join("&");

      if (searchQuery) {
        backendParams += `&search=${searchQuery}`;
      }

      console.log("backendParams", backendParams);

      const params = backendParams + `&perPage=${searchQuery ? 0 : pageSize}&page=${searchQuery ? 0 : pageIndex}`;

      // navigate(`/Masters/stockists?${clientParams.toString()}`);
      const res = await queryStockistDetails(params.replace("&&", "&").replace("?&", "?"));
      if (res?.status === 200) {
        setStockistData(res?.data?.data?.data);
        setTotalRowCount(res?.data?.data?.totalDataCount || 10);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("companyList", companyList);

  const columns = useMemo(
    () => [
      columnHelper.accessor("medvol_code", {
        cell: (info) => <p> {info.getValue()} </p>,
        header: () => "Medvol Code",
        enableSorting: true,
      }),
      columnHelper.accessor("firm_name", {
        cell: (info: any) => <div className="pl-3"> {info.getValue()}</div>,
        header: () => "Stockist Name",
        enableSorting: true,
      }),

      columnHelper.accessor("city", {
        cell: (info: any) => <div className="pl-3">{info.getValue()?.city_name}</div>,
        header: () => "City",
        enableSorting: true,
      }),
      columnHelper.accessor("city", {
        cell: (info: any) => <div className="pl-3">{info.getValue()?.district_code.district_name}</div>,
        header: () => "District",
        enableSorting: true,
      }),
      columnHelper.accessor("city", {
        cell: (info: any) => <div className="pl-3">{info.getValue()?.district_code.state_code?.state_name}</div>,
        header: () => "State",
        enableSorting: true,
      }),
      columnHelper.accessor("doctor_name", {
        cell: (info) => (
          <Button onClickCapture={() => rowHandler(info.row)} className="text-blue-600 underline focus:ring-blue-600">
            View
          </Button>
        ),
        header: () => "Action",
        enableSorting: true,
      }),
    ],
    []
  );

  // const retrieveCounterHandler = async () => {
  //   try {
  //     setIsLoading(true);

  //     const res = await getStockist();
  //     if (res?.status === 200) {
  //       // setCounterDetails(res.data);
  //       setStockistData(res?.data?.data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const rowHandler = (row: any) => {
    window.sessionStorage.setItem("stockistMasterId", row.original?.stockist_id ?? "");
    dispatch(stockistActions.setSelectedStockistData({}));
    navigate(`/masters/stockists/details`);
  };

  const handleQuery = (value: string) => {
    const trimmedValue = value.trim();
    setSearchQuery(trimmedValue);
  };

  let queryTimerId: ReturnType<typeof setTimeout> | null = null;

  useEffect(() => {
    if (queryTimerId) clearTimeout(queryTimerId);
    queryTimerId = setTimeout(() => {
      setIsLoading(true);

      let backendParams = "?";
      let clientParams = "";

      if (searchQuery) {
        backendParams = `?search=${searchQuery}`;
      }

      resetPagination();
      if (!searchQuery) {
        setStockistData([]);
        setIsLoading(false);
        return;
      }

      /*
        filters data start here
        */
      if (stockistData === null) setStockistData([]);

      if (backendParams?.includes("?")) {
        backendParams += `&`;
      }

      const companyClientParams = appendClientParams(Array.isArray(filters?.company) ? filters.company : [], "c", "name", "code");
      const companyParams = appendParams(Array.isArray(filters?.company) ? filters.company : [], "company_code", "code");

      const cityParams = appendParams(Array.isArray(filters?.city) ? filters.city : [], "city_code", "city_code");
      const districtParams = appendParams(Array.isArray(filters?.district) ? filters.district : [], "district_id", "district_code");
      const stateParams = appendParams(Array.isArray(filters?.state) ? filters.state : [], "state_id", "state_code");
      const statusParams = appendParams(Array.isArray(filters?.status) ? filters.status : [], "active_status", "key");
      const stockistTypeParams = appendParams(Array.isArray(filters?.stockistType) ? filters.stockistType : [], "stockist_type", "stockist_type");
      const cityClientParams = appendClientParams(Array.isArray(filters?.city) ? filters.city : [], "rc", "city_name", "city_code");

      backendParams += [companyParams, cityParams, districtParams, stateParams, statusParams, stockistTypeParams].filter(Boolean).join("&");

      clientParams += [companyClientParams, cityClientParams].filter(Boolean).join("&");

      /*
        filters data end here
        */

      console.log("backendParams", backendParams);

      const params = backendParams + "&perPage=0&page=0";

      queryStockistDetails(params.replace("&&", "&").replace("?&", "?"))
        .then((res: any) => {
          if (res?.data) {
            setStockistData(res?.data?.data?.data);
            setTotalRowCount(res?.data?.data?.totalDataCount || 10);
          }
        })
        .catch((err: any) => {
          console.log("err", err);
          toast({ description: "Error fetching data" });
        })
        .finally(() => setIsLoading(false));
    }, 500);

    return () => {
      if (queryTimerId) clearTimeout(queryTimerId);
    };
  }, [searchQuery]);

  const ref = useOutsideClick(() => {
    setIsFiltersShown(false);
  });

  useEffect(() => {
    if (isMount.current === false) {
      isMount.current = true;
      return;
    }

    retrieveCounterHandler();
  }, [pageSize, pageIndex]);

  let pageCount = Math.ceil(totalRowCount / (pageSize ?? 1));
  if (totalRowCount % 10 === 0) {
    pageCount--;
  }

  return (
    <StockistLayout>
      <Transition
        as={Fragment}
        show={isFiltersShown}
        enter="transition transition ease-in-out duration-200 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition  ease-in-out duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed right-0 inset-0 top-0 z-[500]">
          <StockistsFilter
            ref={ref}
            companyDetails={companyList || []}
            cityDetails={cityList || []}
            districtDetails={districtList || []}
            stateDetails={stateList || []}
            statusDetails={statusList || []}
            stockistTypeDetails={stockistTypeList || []}
            onCloseClick={() => setIsFiltersShown(false)}
          />
        </div>
      </Transition>
      <header className="flex px-[3.75rem] items-center justify-between py-5 border-b-[1px] border-b-[#E9E9E9]">
        <div>
          <Breadcrumb
            currentActive="Stockists"
            dataOrigin={[
              { name: "Masters", path: "masters" },
              { name: "Stockist", path: "stockists" },
            ]}
          />
          <h1 className="text-[#49484C] font-bold text-[2rem]">Stockist Database</h1>
        </div>

        <div className="flex items-center gap-2 ">
          <Input
            placeholder="Filter"
            className="py-[0.7rem] px-3 rounded-lg"
            showIcon={true}
            value={searchQuery}
            onChange={(e) => handleQuery(e.target.value)}
          />
          <Button
            className="relative flex items-center gap-1 px-4 py-3 border"
            onClick={() => {
              setIsFiltersShown(true);
            }}
          >
            Filters <img src={FilterIcon} className="w-5 h-5" />
            {filterCount > 0 && (
              <span className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full">
                {filterCount}
              </span>
            )}
          </Button>
          <Button
            onClick={retrieveCounterHandler}
            isLoading={isLoading}
            loaderSize="medium"
            className="bg-[#F3EFFC] py-2 px-4 border-2 text-[#42267F] border-[#7F56D9] ml-auto font-semibold text-base"
          >
            Retrieve
          </Button>
        </div>
      </header>

      <main>
        <div className="px-[3.75rem] my-10">
          <Table
            columns={columns}
            data={stockistData}
            onRowClick={rowHandler}
            manualPagination={true}
            pageCount={pageCount}
            pagination={pagination}
            onPaginationChange={onPaginationChange}
          />
        </div>
      </main>
    </StockistLayout>
  );
};

export default StockistMaster;
