import { Button } from "src/components";
import { flattenObject } from "src/constants/functions";
import { useState } from "react";
import downArrow from "src/assets/down-arrow.svg";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/features/fields/components/Tooltip";
import { switchString } from "src/utils/utils";
import saveFile from "src/features/fields/assets/saveFile.svg";
import send from "src/features/fields/assets/send.svg";
import cross from "src/features/fields/assets/cross.svg";
import star from "src/features/fields/assets/star.svg";

const TableData = [
  {
    description: "Atarax Anti-Itch Lotion 100ml",
    code: "3000028054",
    batch: "BAIL24007",
    quantity: 10,
    rate: "₹331.20",
    productDiscount: "₹0.00",
    scheme: 0,
    cashDiscount: "15.98%",
    gst: "18.00%",
    netAmount: "₹5,710.00",
    netAmountEntry: "₹5,710.00",
  },
  {
    description: "Xyzal 5.5mg/ml 60ml Syrp",
    code: "3000028054",
    batch: "ZP40002",
    quantity: 10,
    rate: "₹128.93",
    productDiscount: "₹0.00",
    scheme: 0,
    cashDiscount: "15.98%",
    gst: "18.00%",
    netAmount: "₹5,710.00",
    netAmountEntry: "₹5,710.00",
  },
  {
    description: "Xyzal 5.5mg/ml 60ml Syrp",
    code: "3000028054",
    batch: "ZP40002",
    quantity: 10,
    rate: "₹128.93",
    productDiscount: "₹0.00",
    scheme: 0,
    cashDiscount: "15.98%",
    gst: "18.00%",
    netAmount: "₹5,710.00",
    netAmountEntry: "₹5,710.00",
  },
];

const TableData2 = [
  {
    type: "Quantity",
    invoiceData: "10",
    orderedData: "10",
    modifiedData: "12",
  },
  {
    type: "Scheme / Product Discount / Net Rate / Fixed Price",
    invoiceData: "10 + 2",
    orderedData: "10 + 2",
    modifiedData: "12",
  },
  {
    type: "Cash Discount",
    invoiceData: "4%",
    orderedData: "4%",
    modifiedData: "12",
  },
  {
    type: "PTR",
    invoiceData: "₹300.00",
    orderedData: "₹300.00",
    modifiedData: 12,
  },
  {
    type: "MRP",
    invoiceData: "₹300.00",
    orderedData: "₹300.00",
    modifiedData: 12,
  },
];

const tableData = [
  {
    item_name: "#1",
    item_code: "12345678",
    batch_no: "12345678",
    inv_rate: "₹1234.00",
    inv_mrp: "₹1234.00",
    qty: "25+10",
    discount_defined_by: "Scheme",
    gross_amt: "₹1234.00",
    gst: "12%",
  },
  {
    item_name: "#2",
    item_code: "12345678",
    batch_no: "12345678",
    inv_rate: "₹1234.00",
    inv_mrp: "₹1234.00",
    qty: "25 @33%",
    discount_defined_by: "Discount %",
    gross_amt: "₹1234.00",
    gst: "12%",
  },
  {
    item_name: "#3",
    item_code: "12345678",
    batch_no: "12345678",
    inv_rate: "₹1234.00",
    inv_mrp: "₹1234.00",
    qty: "25",
    discount_defined_by: "Fixed Price",
    gross_amt: "₹1234.00",
    gst: "12%",
  },
  {
    item_name: "#4",
    item_code: "12345679",
    batch_no: "12345678",
    inv_rate: "₹1234.00",
    inv_mrp: "₹1234.00",
    qty: "25+10",
    discount_defined_by: "Scheme",
    gross_amt: "₹1234.00",
    gst: "12%",
  },
];

const tableData2 = [
  {
    item_name: "#1",
    item_code: "12345678",
    batch_no: "12345678",
    inv_rate: "₹1234.00",
    inv_mrp: "₹1234.00",
    qty: "25+10",
    discount_defined_by: "Scheme",
    gross_amt: "₹1234.00",
    gst: "12%",
    action: <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black">Register</Button>,
  },
  {
    item_name: "#2",
    item_code: "12345678",
    batch_no: "12345678",
    inv_rate: "₹1234.00",
    inv_mrp: "₹1234.00",
    qty: "25 @33%",
    discount_defined_by: "Discount %",
    gross_amt: "₹1234.00",
    gst: "12%",
    action: <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black">Register</Button>,
  },
];

const dummyInvoiceData = [
  {
    Company: "Dr. Reddy's Laboratories",
    "Invoice no.": "#123-456-789",
    "Invoice date": "2 Aug 2024",
    "Reference ID": "ID-123456789",
    Counter: "Shaji Pharma",
    "Counter code": "ID-123456789",
    "Link Type": "DRDS",
    Stockist: "Dr. Reddy's Laboratories",
    "Stockist code": "#123-456-789",
    Pharmacy: "Shaji Pharmaceuticals",
    "Pharmacy code": "#123-456-789",
    "Clinic/Hospital": "Shaji Pharma",
    Address: "-",
    City: "-",
    "MCI no.": "123-456-789",
    "GST Status": "Cancelled/Valid",
    "GST No.": "5643-2343-2343",
    "E-Inv.Applicability": "Yes",
    "QR/IRN": "QR",
    "DL Present": "Yes",
    "DL no.": "123-456-789",
    "MTD Value": "₹12345",
    "Approved Inv Val": "#123-456-789",
  },
];

let subrow: any[] = [];
let subrow2: any[] = [];

tableData.forEach((element: any) => {
  subrow.push(flattenObject(element));
});
tableData2.forEach((element: any) => {
  subrow2.push(flattenObject(element));
});

export const InvoiceNine = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="max-w-[1400px] mx-auto mt-10">
      <div className="p-5 rounded-xl border border-[#d0d0d0] shadow-sm">
        <header className="w-full">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <img src={star} className="p-1 mr-2 w-8 h-8 rounded-lg border border-[#d0d0d0] shadow-sm" alt="star" />
              <h1 className={"font-semibold text-lg text-[1.5rem] mr-4"}>Invoice #123-456</h1>
              <h1 className={`font-semibold  flex items-center text-xs h-6 px-2 rounded-full ${"bg-[#FFF1E3] text-[#5E4200]"}`}>
                Invoice not mapped
                <img className="ml-1 w-3 h-3 cursor-pointer" src={downArrow} alt="down-arrow" />
              </h1>
            </div>
            <div className="flex gap-x-1.5">
              <Button className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600">
                <img src={saveFile} className="mr-1 w-4 h-4" alt="save-file" />
                Save{" "}
              </Button>
              <Button className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600">
                <img src={send} className="mr-1 w-4 h-4" alt="send" />
                Send{" "}
              </Button>
              <Button className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600">
                <img src={cross} className="mr-1 w-4 h-4 invert" alt="cross" />
                Clear{" "}
              </Button>
            </div>
          </div>
        </header>
        <div>
          <div className="flex justify-between pb-5 mt-10 border-b-2 border-gray-200">
            <p className="text-xl font-semibold">Header Information</p>
            <div className="flex gap-x-2">
              <img
                onClick={handleToggle}
                className={`transition-transform cursor-pointer duration-300 ${isExpanded ? "rotate-180" : ""}`}
                src={downArrow}
                alt="down-arrow"
              />
            </div>
          </div>
          <div
            className={`overflow-hidden transition-all duration-500 ease-in-out ${isExpanded ? "opacity-100 max-h-[1000px]" : "max-h-0 opacity-0"}`}
            style={{ transitionProperty: "max-height, opacity" }}
          >
            <div className={"grid grid-cols-1 w-[100%] py-5 mt-5 xl:grid-cols-3 gap-y-5 mb-5 "}>
              {dummyInvoiceData.map(
                (data: any) =>
                  Object.entries(data)?.map(
                    ([key, value]) =>
                      typeof value === "string" &&
                      value !== "" && (
                        <div key={key?.toString()} className={"grid grid-cols-2 text-sm xl:grid-cols-2"}>
                          <p className="text-[#49484C] place-self-center mr-auto whitespace-break-spaces text-sm leading-[0.02rem] font-normal not-italic">
                            {switchString(key)} :
                          </p>
                          <div className="mr-auto">
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <p className="text-[#1B1A1F] text-sm text-left mr-auto">
                                    {typeof value === "string" ? (value?.length > 20 ? value.slice(0, 20) + "..." : value) : ""}
                                  </p>
                                </TooltipTrigger>
                                <TooltipContent>{typeof value === "string" ? value : ""}</TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        </div>
                      )
                  )
              )}
            </div>
          </div>
        </div>
        <div className="mt-10 mb-10 rounded-lg">
          <div className="flex justify-between items-center pb-5 border-b-2 border-gray-200">
            <p className="mb-2 text-lg font-semibold">Invoice Details</p>
            {/* <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
            <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
          </Button> */}
          </div>

          <table className="mt-5 min-w-full border border-gray-200">
            <thead className="w-full bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Item code</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Item description</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Batch</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Qty.</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Rate</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Prd. Dis.</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Scheme</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Cash Dis.</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">GST % </th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Net Amt.</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Order Amt. Entry</th>
                <th className="px-4 py-2 w-36 text-sm text-left text-gray-600">Order Map</th>
              </tr>
            </thead>
            <tbody>
              {TableData?.map((item, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-50 text-sm" : "text-sm"}>
                  <td className="px-4 py-2 border-t">{item.code}</td>
                  <td className="px-4 py-2 border-t">{item.description}</td>
                  <td className="px-4 py-2 border-t">{item.batch}</td>
                  <td className="px-4 py-2 border-t">{item.quantity}</td>
                  <td className="px-4 py-2 border-t">{item.rate}</td>
                  <td className="px-4 py-2 border-t">{item.productDiscount}</td>
                  <td className="px-4 py-2 border-t">{item.scheme}</td>
                  <td className="px-4 py-2 border-t">{item.cashDiscount}</td>
                  <td className="px-4 py-2 border-t">{item.gst}</td>
                  <td className="px-4 py-2 border-t">{item.netAmount}</td>
                  <td className="px-4 py-2 border-t">{item.netAmountEntry}</td>
                  <td className="px-4 py-2 border-t">
                    <Button className="w-full h-8 font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0]">View Details</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-10 border border-[#d0d0d0] p-5 rounded-xl">
          <p className="text-lg font-semibold">Xyzal 5.5mg/ml 60ml Syrp</p>

          <table className="mt-5 min-w-full border border-gray-200">
            <thead className="w-full bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-sm font-normal text-left text-gray-600"></th>
                <th className="px-4 py-2 text-sm font-normal text-left text-gray-600">Invoice Data</th>
                <th className="px-4 py-2 text-sm font-normal text-left text-gray-600">Ordered Data</th>
                <th className="px-4 py-2 text-sm font-normal text-left text-gray-600">Modified Data</th>
              </tr>
            </thead>
            <tbody>
              {TableData2?.map((item, index) => (
                <tr key={index} className="h-12 text-sm">
                  <td className="px-4 py-2 text-gray-500 border-t">{item.type}</td>
                  <td className="px-4 py-2 border-t">{item.invoiceData}</td>
                  <td className="px-4 py-2 border-t">{item.orderedData}</td>
                  <td className="px-4 py-2 border-t">
                    <input type="text" value={item.modifiedData} className="px-2 py-1 w-full rounded-md border border-1-black" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-10 border border-[#d0d0d0] p-5 rounded-xl">
        <div className="flex justify-between items-center pb-5">
          <p className="text-lg font-semibold">Final Mapped Data</p>
          {/* <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
            <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
          </Button> */}
          <Button className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600">
            Reset
          </Button>
        </div>

        <table className="min-w-full border border-gray-200">
          <thead className="w-full bg-gray-100">
            <tr>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Item code</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Item description</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Batch</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Qty.</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Rate</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Prd. Dis.</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Scheme</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Cash Dis.</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">GST % </th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Net Amt.</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Order Amt. Entry</th>
            </tr>
          </thead>
          <tbody>
            {TableData?.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-50 text-sm h-12" : "text-sm h-12"}>
                <td className="px-4 py-2 border-t">{item.code}</td>
                <td className="px-4 py-2 border-t">{item.description}</td>
                <td className="px-4 py-2 border-t">{item.batch}</td>
                <td className="px-4 py-2 border-t">{item.quantity}</td>
                <td className="px-4 py-2 border-t">{item.rate}</td>
                <td className="px-4 py-2 border-t">{item.productDiscount}</td>
                <td className="px-4 py-2 border-t">{item.scheme}</td>
                <td className="px-4 py-2 border-t">{item.cashDiscount}</td>
                <td className="px-4 py-2 border-t">{item.gst}</td>
                <td className="px-4 py-2 border-t">{item.netAmount}</td>
                <td className="px-4 py-2 border-t">{item.netAmountEntry}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="p-6 mt-10 rounded-xl border border-[#d0d0d0] w-[360px] shadow-sm">
        <p className="mb-2 text-lg font-semibold">Calculations</p>
        <div className="flex flex-col gap-y-1 pb-10">
          <p className="flex justify-between py-2 text-sm">
            Subtotal <span className="">₹4,800.00</span>
          </p>
          <p className="flex justify-between py-2 text-sm">
            Cash Discount % <input type="text" className="px-10 py-1 w-10 rounded-md border border-1-black" />
          </p>
          <p className="flex justify-between py-2 text-sm">
            Cash Discount Amount <input type="text" className="px-10 py-1 w-10 rounded-md border border-1-black" />
          </p>
          <p className="flex justify-between py-2 text-sm">
            Total (as calculated) <span className="font-semibold">₹4,944.00</span>
          </p>
          <p className="flex justify-between py-2 text-sm">
            Net Inv. Amt. <input type="text" className="px-10 py-1 w-10 rounded-md border border-1-black" />
          </p>
          <Button className="w-full h-9 mt-5 font-normal text-white bg-[#8051FF] shadow-sm">Approve Invoice</Button>
        </div>
      </div>
    </div>
  );
};
