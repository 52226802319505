import { Autocomplete, Button } from "src/components";
import crossIcon from "../../assets/cross.svg";
import { useEffect, useState } from "react";
import { getRejectionCategory, rejectInvoice } from "src/services/invoice-api";
import { useToast } from "src/hooks/useToast";

const InvoiceReject = ({ onCloseModal, invoiceData }: { onCloseModal: () => void; invoiceData: any }) => {
  const [remarks, setRemarks] = useState<string>("");
  const [rejectionCategory, setRejectionCategory] = useState<any>([]);
  const [rejectionCategoryId, setRejectionCategoryId] = useState<any>(null);

  const { toast } = useToast();

  const fetchRejectionCategory = async () => {
    const res = await getRejectionCategory();
    setRejectionCategory(res?.data?.data);
  };

  useEffect(() => {
    fetchRejectionCategory();
  }, []);

  const handleInvoiceReject = async () => {
    const payload = {
      data: {
        stockist_invoice_attachment_id: invoiceData?.stockist_invoice_attachment_id,
        rejection_inv_category_id: rejectionCategoryId,
        remarks: remarks,
      },
      primary_key: invoiceData?.stockist_invoice_attachment_id,
      event_type: "STOCKIST_ATTACHMENT_REJECT",
    };
    try {
      if (!rejectionCategoryId) {
        toast({ description: "Please select a category of rejection", variant: "destructive" });
        return;
      }
      const res = await rejectInvoice(payload);
      console.log(res);
      if (res?.data?.status === "success") {
        toast({ description: "Invoice rejected successfully", variant: "default" });
        onCloseModal();
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      onCloseModal();
    }
  };

  return (
    <div className="flex flex-col gap-y-2 w-[500px] h-[400px] mx-auto bg-white p-4 border border-gray-300 shadow-lg rounded-lg">
      <div className="flex justify-between items-center">
        <p className="text-normal py-1 font-semibold text-[#1B1A1F]">Reject Invoice</p>
        <Button className="bg-transparent -mr-3" onClick={onCloseModal}>
          <img src={crossIcon} alt="cross" className="w-5 h-5 font-bold invert cursor-pointer" />
        </Button>
      </div>
      <p className="text-sm mb-2 font-normal text-[#666666]">Select a category of rejection</p>
      <Autocomplete
        inputClassName="w-full"
        placeholder="E-Invoice is mandatory"
        suggestionList={rejectionCategory}
        displayKey="rejection_inv_category_desc"
        uniqueKey="rejection_inv_category_id"
        onSelectionChange={(e: any) => setRejectionCategoryId(e?.rejection_inv_category_id)}
      />
      <p className="pt-2">Remarks</p>
      <textarea
        value={remarks}
        onChange={(e) => setRemarks(e.target.value)}
        placeholder="Write your remarks here"
        className="p-2 mb-2 resize-none border bg-[#f7f7f7] max-h-[120px] h-[120px] border-gray-300 rounded-md"
      />
      <Button className="text-white bg-[#8051FF]" onClick={handleInvoiceReject}>
        Proceed
      </Button>
    </div>
  );
};

export default InvoiceReject;
