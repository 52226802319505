import { Transition } from "@headlessui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { FilterIcon } from "lucide-react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "src/components";
import { ordActions } from "src/context/order-slice";
import { RootState } from "src/context/store";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { usePagination } from "src/hooks/usePagination";
import { getAllCompanyDetails } from "src/services/company-api";
import { fetchStatusDetails, fetchStockistFilterData, pendingOrderDetails } from "src/services/order-api";
import Badge from "../../components/Badge";
import Table from "../../components/Table";
import OrdersFilter from "../../container/OrdersFilter";
import { queryDivisionDetails } from "../../services/division-api";

export enum OrdersFilterOn {
  ORDER_CODE = "order_code",
  ORDER_CREATED_DATE = "created_date",
  COUNTER_CODE = "counter_code",
  ORDER_CREATED_BY = "actor_name",
  ORDER_OPERATION_STATUS = "operation_status",
  COMPANY = "company_name",
  DIVISION = "division_name",
}

// interface FilterItem {
//   id?: string;
//   name?: string;
//   code?: string | number;
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   [key: string]: any; // Add an index signature
// }

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("order_code", {
    cell: (info) => info.getValue(),
    header: () => "Order Code",
    enableSorting: true,
  }),
  columnHelper.accessor("counter_code", {
    cell: (info) => info.getValue(),
    header: () => "Counter Code",
    enableSorting: true,
  }),
  columnHelper.accessor("actor_name", {
    cell: (info) => info.getValue(),
    header: () => "Created By",
    enableSorting: true,
  }),
  columnHelper.accessor("created_date", {
    cell: (info) => {
      const dateTime = info.getValue();
      const date = moment(dateTime).format("MMMM Do YYYY");
      const time = moment(dateTime).format("h:mm a");
      return `${date} ${time}`;
    },
    header: () => "Created Date",
    enableSorting: true,
  }),
  columnHelper.accessor("company_name", {
    cell: (info) => info.getValue(),
    header: () => "Company",
    enableSorting: true,
  }),
  columnHelper.accessor("division_name", {
    cell: (info) => info.getValue(),
    header: () => "Division",
    enableSorting: true,
  }),
  columnHelper.accessor("orderStatusHdr.order_status_desc", {
    header: () => "Operation Status",
    enableSorting: true,
    cell: (info: any) => {
      const status = info.getValue();
      let valueToShow = "pending";
      let variant = "default";
      if (status?.toString()?.toLowerCase() === "pending") {
        variant = "warning";
        valueToShow = "Pending";
      } else if (status?.toString()?.toLowerCase() === "reject") {
        variant = "error";
        valueToShow = "Rejected";
      } else if (status?.toString()?.toLowerCase() === "approval" || status?.toString()?.toLowerCase() === "approve") {
        variant = "success";
        valueToShow = "Approved";
      } else if (status?.toString()?.toLowerCase() === "post") {
        variant = "info";
        valueToShow = "Post";
      } else {
        variant = "default";
        valueToShow = status?.toString();
      }

      return (
        <Badge variant={variant} className="capitalize">
          {valueToShow}
        </Badge>
      );
    },
  }),
];

const OrderPendingScreen = () => {
  const { currentPsr } = useSelector((state: RootState) => state.auth);
  const { pendingOrder } = useSelector((state: RootState) => state.ord);

  // const [sortField, setSortField] = useState<string>("created_date");
  // const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");

  const [totalRowCount, setTotalRowCount] = useState(0);
  const { pagination, onPaginationChange, pageSize, pageIndex, resetPagination } = usePagination();
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();
  const [isFiltersShown, setIsFiltersShown] = useState<boolean>(false);
  const { OrderFilters, OrdersFiltersData } = useSelector((state: RootState) => state.ord);
  const [globalSearch, setGlobalSearch] = useState("");
  const [filtersApplied, setFiltersApplied] = useState(false); // Tracks if filters are applied

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const isMount = useRef(false);

  // const filters = useSelector((state: RootState) => state.ord.OrderFilters);
  useEffect(() => {
    setFiltersApplied(true);
  }, [globalSearch]);

  const ref = useOutsideClick(() => {
    setIsFiltersShown(false);
  });

  const fetchPendingOrder = async () => {
    // if (currentPsr?.emp_position_hdr?.position_code && OrderFilters?.company?.[0]?.code) {
    if (currentPsr?.emp_position_hdr?.position_code && filtersApplied) {
      try {
        setLoading(true);
        const res = await pendingOrderDetails(
          currentPsr?.emp_position_hdr?.position_code,
          pageIndex,
          pageSize,
          globalSearch,
          OrderFilters?.company?.[0]?.code || "",
          OrderFilters?.division?.[0]?.company_divisioncode || "",
          OrderFilters?.stockist?.[0]?.stockist_company_lnk?.[0]?.id || "",
          OrderFilters?.status?.[0]?.order_status_id || ""
        );
        dispatch(ordActions.setPendingOrder(res?.data?.data));
        setTotalRowCount(res?.data?.totalCount);
        setSearchParams({
          pageSize: pageSize.toString(),
          pageIndex: pageIndex.toString(),
        });

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch pending orders:", error);
      }
    }
  };

  let pageCount = Math.ceil(totalRowCount / (pageSize ?? 1));
  if (totalRowCount % 10 === 0) {
    pageCount--;
  }

  const rowClickHandler = async (row: any) => {
    window.sessionStorage.setItem("counterOrderId", row?.original?.counter_code);

    try {
      dispatch(ordActions.setSelectedCounterData({}));
      window.sessionStorage.setItem("counterCompanyLocationId", row?.original?.counter_company_location_id);
      window.sessionStorage.setItem("order_id_int", row?.original?.order_id_int);
    } catch (err) {
      console.log(err);
    }

    navigate(`/transactions/orders/details/${row?.original?.counter_code}`);
  };

  let timerId: any;
  const handleSearch = (searchQuery: string) => {
    if (timerId) clearTimeout(timerId);

    timerId = setTimeout(() => {
      resetPagination();
      // setSearch(`?pageIndex=1&pageSize=${pageSize}`);
      setGlobalSearch(searchQuery);
    }, 500);
  };

  // const companyFilterHandler = (details: FilterItem[]) => {
  //   dispatch(ordActions.setOrderFilters({ key: "company", value: [details] }));
  // };

  useEffect(() => {
    getAllCompanyDetails()
      .then((res) => dispatch(ordActions.setOrdersFiltersData({ key: "company", value: res.data })))
      .catch((err) => console.log(err));

    // const ActionType = [
    //   { label: "Waiting for Approval", value: "waiting" },
    //   { label: "Reject", value: "rejected" },
    //   { label: "Complete", value: "complete" },
    // ];

    // dispatch(ordActions.setOrdersFiltersData({ key: "status", value: ActionType }));
  }, [dispatch]);

  useEffect(() => {
    if (OrderFilters?.company?.length) {
      queryDivisionDetails(`/details/?fields=division_name&company_code=${OrderFilters?.company?.[0]?.code}`)
        .then((res) => dispatch(ordActions.setOrdersFiltersData({ key: "division", value: res?.data })))
        .catch((err) => console.log(err));

      fetchStockistFilterData(OrderFilters?.company?.[0]?.code)
        .then((res) => dispatch(ordActions.setOrdersFiltersData({ key: "stockist", value: res?.data?.data })))
        .catch((err) => console.log(err));

      fetchStatusDetails(OrderFilters?.company?.[0]?.code)
        .then((res) => dispatch(ordActions.setOrdersFiltersData({ key: "status", value: res?.data })))
        .catch((err) => console.log(err));
    } else {
      dispatch(ordActions.setOrdersFiltersData({ key: "division", value: [] }));
    }
  }, [OrderFilters?.company]);

  useEffect(() => {
    dispatch(ordActions.setPendingOrder([]));

    if (filtersApplied) {
      fetchPendingOrder();
    }
  }, [filtersApplied, pageSize, pageIndex, globalSearch]);

  // const handleSortFieldChange = (selectedItem: { name: string; code: string }) => {
  //   setSortField(selectedItem?.code);
  // };

  // const handleSortOrderChange = (selectedOrder: "ASC" | "DESC") => {
  //   setSortOrder(selectedOrder);
  // };

  useEffect(() => {
    resetPagination();
    fetchPendingOrder();
  }, [OrderFilters]);

  useEffect(() => {
    resetPagination();
    const filtersSet = Object.values(OrderFilters).some((filter) => filter && filter.length > 0);
    setFiltersApplied(filtersSet); // Set true if any filter is applied
  }, [OrderFilters]);

  // if (loading)
  //   return (
  //     <div className="flex items-center justify-center my-10">
  //       <Spinner />
  //     </div>
  //   );

  return (
    <div>
      <div className="flex items-center justify-end w-full gap-2">
        {/* <div className="flex items-center w-[800px] gap-2">
          <span className="text-base not-italic font-normal line-height-normal inline-block w-[500px]">Company name:</span>
          <Autocomplete
            onSelectionChange={companyFilterHandler}
            inputClassName="w-[300px]"
            placeholder="Type a company name..."
            suggestionList={OrdersFiltersData?.company || []}
            displayKey="name"
            uniqueKey="code"
            defaultValue={filters?.company?.[0]?.shortname}
          />
          <Autocomplete
            onSelectionChange={handleSortFieldChange}
            inputClassName="w-[300px]"
            placeholder="Select a sort field..."
            suggestionList={Object.keys(OrdersFilterOn).map((key) => ({
              name: key.replace(/_/g, " "),
              code: OrdersFilterOn[key as keyof typeof OrdersFilterOn],
            }))}
            displayKey="name"
            uniqueKey="code"
          />
          <Autocomplete
            onSelectionChange={(item: any) => handleSortOrderChange(item?.code as "ASC" | "DESC")}
            inputClassName="w-[300px]"
            placeholder="Sort Order"
            suggestionList={[
              { name: "Ascending", code: "ASC" },
              { name: "Descending", code: "DESC" },
            ]}
            displayKey="name"
            uniqueKey="code"
            defaultValue={sortOrder}
          />
        </div> */}
        <div className="flex items-center justify-end float-right gap-2">
          <Button
            className="gap-1 px-4 mt-2 ml-2 border "
            onClick={() => {
              setIsFiltersShown(true);
            }}
          >
            Filters <FilterIcon className="w-5 h-5" />
          </Button>
          <Button onClick={fetchPendingOrder} loaderSize="small" className="h-auto py-2 mt-2 border">
            Retrieve
          </Button>
        </div>
      </div>

      {/* <FilterAccordion
        uniqueKey="code"
        displayKey="shortname"
        onSelectingFilter={companyFilterHandler}
        title="Company Name"
        arrayToFilter={OrdersFiltersData?.company || []}
        defaultValue={filters?.company}
        isSingleSelect={true}
      /> */}

      <Transition
        as={Fragment}
        show={isFiltersShown}
        enter="transition transition ease-in-out duration-200 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition  ease-in-out duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed right-0 inset-0 top-0 z-[500]">
          <OrdersFilter
            ref={ref}
            companyDetails={OrdersFiltersData?.company || []}
            divisionDetails={OrdersFiltersData?.division || []}
            pendingStatus={OrdersFiltersData?.status || []}
            stockistDetails={OrdersFiltersData?.stockist || []}
            onCloseClick={() => setIsFiltersShown(false)}
          />
        </div>
      </Transition>

      <Table
        columns={columns}
        title="Orders"
        subtitle="View and manage orders"
        data={pendingOrder ?? []}
        isLoading={loading}
        onRowClick={rowClickHandler}
        isGlobalSearch={true}
        manualPagination={true}
        pageCount={pageCount}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        showSearch={true}
        onGlobalSearch={handleSearch}
      />
    </div>
  );
};

export default OrderPendingScreen;
