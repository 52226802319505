import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface StockistState {
  clusterCompanyFormData: string | any;
  filterCount: any;
  companyList: any;
  cityList: any;
  districtList: any;
  stateList: any;
  statusList: any;
  stockistTypeList: any;
  addEditFormData: any;
  linkedCompaniesData: any[];
  stockistFormData: any;
  selectedStockistData: any;
  showStockistMainForm: boolean;
  mainFormEditMode: boolean;
  stockistLinkEditMode: boolean;
  selectedDetailsTabIndex: number;
  independentCompanyAdd: boolean;
  clusterFormSectionCounter: number;
  stockistCompanystatus: boolean;
  isAddStockist: boolean;
  filters: {
    company: any[] | undefined;
    city: any[] | undefined;
    state: any[] | undefined;
    district: any[] | undefined;
    status: any[] | undefined;
    stockistType: any[] | undefined;
  };
  isResetFilter: boolean;
}

const stockistSlice = createSlice({
  name: "stockist",
  initialState: {
    clusterCompanyFormData: {},
    loggedInUser: null,
    addEditFormData: {},
    linkedCompaniesData: [],
    filterCount: null,
    companyList: [],
    cityList: null,
    districtList: [],
    stateList: [],
    statusList: [],
    stockistTypeList: [],
    stockistFormData: {},
    selectedStockistData: {},
    showStockistMainForm: false,
    mainFormEditMode: false,
    selectedDetailsTabIndex: 0,
    independentCompanyAdd: false,
    clusterFormSectionCounter: 1,
    stockistCompanystatus: false,
    stockistLinkEditMode: false,
    isAddStockist: false,
    filters: {
      company: undefined,
      city: undefined,
      state: undefined,
      district: undefined,
      status: undefined,
      stockistType: undefined,
    },
    isResetFilter: false,
  } as StockistState,
  reducers: {
    linkClusterCompanyFormData(state, action: PayloadAction<any>) {
      state.clusterCompanyFormData = action.payload;
    },
    setFilterCount(state, action: PayloadAction<any>) {
      state.filterCount = action.payload || {};
    },
    setCompanyList(state, action: PayloadAction<any>) {
      state.companyList = action.payload;
    },
    setCityList(state, action: PayloadAction<any>) {
      state.cityList = action.payload;
    },
    setStatusList(state, action: PayloadAction<any[]>) {
      state.statusList = action.payload;
    },
    setDistrictList(state, action: PayloadAction<any[]>) {
      state.districtList = action.payload;
    },
    setStateList(state, action: PayloadAction<any[]>) {
      state.stateList = action.payload;
    },
    setStockistTypeList(state, action: PayloadAction<any[]>) {
      state.stockistTypeList = action.payload;
    },
    setFormData(state, action: PayloadAction<any[] | any | null | undefined>) {
      const { keyofForm, value } = action.payload;
      state.addEditFormData[keyofForm] = value;
    },
    setStockistLinkFormFullObject(state, action: PayloadAction<any>) {
      state.addEditFormData = action.payload;
    },
    setLinkedCompaniesData(state, action: PayloadAction<any>) {
      state.linkedCompaniesData.push(action.payload);
    },
    setStockistFormData(state, action: PayloadAction<any>) {
      const { keyofForm, value } = action.payload;
      state.stockistFormData[keyofForm] = value;
    },
    setSelectedStockistData(state, action: PayloadAction<any>) {
      state.selectedStockistData = action.payload;
    },
    setShowStockistMainForm(state, action: PayloadAction<boolean>) {
      state.showStockistMainForm = action.payload;
    },
    setStockistCompanystatus(state, action: PayloadAction<boolean>) {
      state.stockistCompanystatus = action.payload;
    },

    setStockistFullFormData(state, action: PayloadAction<any>) {
      state.stockistFormData = action.payload;
    },
    setMainFormEditMode(state, action: PayloadAction<boolean>) {
      state.mainFormEditMode = action.payload;
    },
    setSelectedDetailsTabIndex(state, action: PayloadAction<number>) {
      state.selectedDetailsTabIndex = action.payload;
    },
    setIndependentAddCompany(state, action: PayloadAction<boolean>) {
      state.independentCompanyAdd = action.payload;
    },
    setClusterFormSectionCounter(state, action: PayloadAction<number>) {
      state.clusterFormSectionCounter = action.payload;
    },
    setCompanyFilter(state, action: PayloadAction<any[]>) {
      state.filters.company = action.payload;
    },
    setCityFilter(state, action: PayloadAction<any[]>) {
      state.filters.city = action.payload;
    },
    setDistrictFilter(state, action: PayloadAction<any[]>) {
      state.filters.district = action.payload;
    },
    setStateFilter(state, action: PayloadAction<any[]>) {
      state.filters.state = action.payload;
    },
    setStockistTypeFilter(state, action: PayloadAction<any[]>) {
      state.filters.stockistType = action.payload;
    },
    setStatusFilter(state, action: PayloadAction<any[]>) {
      state.filters.status = action.payload;
    },
    setStockistLinkEditMode(state, action: PayloadAction<boolean>) {
      state.stockistLinkEditMode = action.payload;
    },
    resetLinkedCompanies(state) {
      state.linkedCompaniesData = [];
    },
    setIsResetFilter(state, action: PayloadAction<boolean>) {
      state.isResetFilter = action.payload;
    },
    setIsAddStockist(state, action: PayloadAction<boolean>) {
      state.isAddStockist = action.payload;
    },
  },
});

export const stockistActions = stockistSlice.actions;
export { stockistSlice };
