import { Menu, Transition } from "@headlessui/react";
import React, { FC } from "react";
import { cn } from "src/features/fields/utils/utils";

interface DropdownProps {
  placeholder: string;
  icon?: string;
  menuItems: any[];
  displayKey: string;
  onOptionClick: any;
  currentSelected: string | number | undefined;
  hierarchyDisplay?: string;
  buttonImage?: any;
  imageClassName?: any;
  setModalStates: any;
}

const InvoiceDropdown: FC<DropdownProps> = ({
  placeholder,
  icon,
  menuItems,
  displayKey,
  hierarchyDisplay,
  onOptionClick,
  buttonImage = null,
  currentSelected,
  imageClassName,
  setModalStates,
}) => {
  return (
    <Menu as="div" className="inline-block relative w-full text-left">
      <Menu.Button
        className={cn(
          `${
            imageClassName
              ? imageClassName
              : "inline-flex bg-[#F1F1F1] items-center justify-center w-full gap-2 px-4 py-2 text-sm font-semibold text-black capitalize border rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          }`
        )}
      >
        {!buttonImage && <p className="whitespace-nowrap text-[#49484C] text-sm font-medium"> {currentSelected || placeholder}</p>}
        {buttonImage && <img src={buttonImage} width={20} height={20} alt="menu_image" />}
        {icon && <img className="w-4 h-4" src={icon} alt="down arrow" />}
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-20 px-4 py-2 mt-2 w-52 bg-white rounded-xl divide-gray-100 ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none">
          {menuItems.map((menuItem) => (
            <div key={menuItem[displayKey]} className="w-full text-left text-[#1E1E1E] text-sm font-normal py-1">
              <Menu.Item>
                {() => (
                  <div className="flex items-center gap-x-2">
                    {menuItem.icon && <img src={menuItem.icon} alt="menu_icon" />}
                    <button
                      onClick={() => {
                        onOptionClick(menuItem);
                        setModalStates((prev: any) => ({ ...prev, [menuItem.id]: true }));
                      }}
                      className="py-2 w-full text-left capitalize cursor-pointer hover:text-purple-400"
                    >
                      {hierarchyDisplay ? menuItem[displayKey][hierarchyDisplay] : menuItem[displayKey]}
                    </button>
                  </div>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default InvoiceDropdown;
