export const getInvoiceDetailsCardInfo = (invoiceData: any) => {
  let response: any = {};

  response = {
    Company: invoiceData?.company_name || "-", // Company name
    "Invoice No.": invoiceData?.invoice?.invoice_number || "-", // Invoice number
    "Invoice Date": invoiceData?.created_date
      ? new Date(invoiceData.created_date).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }) // Format date to "6 August 2024"
      : "-",
    "Order Number": invoiceData?.fuid_no || "-", // Order number
    Counter: invoiceData?.counter_code || "-", // Counter
    "Pharmacy Name": invoiceData?.pharmacy_name || "-", // Pharmacy name
    City: invoiceData?.state_district_city || "-", // City
    "Link Type": invoiceData?.link_type === 3 ? "DRDS" : "-", // Link type logic
    Stockist: invoiceData?.stockist_name || "-",
  };

  return response;
};

export const getInvoiceDetailsInfo = (invoiceDetails: any) => {
  let response: any = {};

  response = {
    Company: invoiceDetails?.company_name || "-",
    Stockist: invoiceDetails?.stockist_name || "-",
    "GST Status": invoiceDetails?.gst_validated === true ? "Valid" : "Cancelled",

    "Invoice no.": invoiceDetails?.invoice?.invoice_number || "-",
    "Stockist code": invoiceDetails?.stockist_medvol_code || "-",
    "GST No.": invoiceDetails?.counter_gst_number || "-",

    "Invoice date": invoiceDetails?.created_date || "-",
    Pharmacy: invoiceDetails?.InvoiceAssignment?.user_name || "-",
    "E-Inv.Applicability": "Yes",

    "Reference ID": invoiceDetails?.reference_no || "-",
    "Pharmacy code": invoiceDetails?.InvoiceAssignment?.user_code || "-",
    "QR/IRN": "QR",

    Counter: invoiceDetails?.counter_code || "-",
    "Clinic/Hospital": invoiceDetails?.InvoiceAssignment?.user_name || "-",
    "DL Present": invoiceDetails?.dl_validated === true ? "Yes" : "No",

    "Counter code": invoiceDetails?.counter_code || "-",
    Address: "-",
    "DL no.": invoiceDetails?.dl_number || "-",

    "Link Type": invoiceDetails?.link_type === 3 ? "DRDS" : "-",
    City: "-",
    "MTD Value": "₹12345",
    "MCI no.": invoiceDetails?.counter_mci_number || "-",
  };

  return response;
};
