import React from "react";

interface OrderStatusProps {
  orderStatusCode: number;
  orderStatusText: string;
}

const orderStatusMap: Record<number, { bgColor: string; textColor: string }> = {
  30: { bgColor: "#FFF9ED", textColor: "#996B0F" },
  33: { bgColor: "#FFF9ED", textColor: "#996B0F" },
  6: { bgColor: "#FFF9ED", textColor: "#996B0F" },
  // 30: { bgColor: "#FFF9ED", textColor: "#996B0F" },
  1: { bgColor: "#FFF9ED", textColor: "#996B0F" },
  0: { bgColor: "#D3F6E4", textColor: "#207A65" },
  //   3: { text: "Partially Invoiced", bgColor: "#F2F3FE", textColor: "#586AF5" },
  //   4: { text: "Order Escalated", bgColor: "#FFF1F3", textColor: "#661F28" },
  7: { bgColor: "#FBEEEE", textColor: "#A92626" },
  2: { bgColor: "#FBEEEE", textColor: "#A92626" },
  31: { bgColor: "#FBEEEE", textColor: "#A92626" },
  32: { bgColor: "#FBEEEE", textColor: "#A92626" },
  14: { bgColor: "#FBEEEE", textColor: "#A92626" },
  //   6: { text: "Order Cancelled", bgColor: "#FBEEEE", textColor: "#D33030" },
  10: { bgColor: "#FBEEEE", textColor: "#D33030" },
  //   11: { text: "Order Replenished", bgColor: "#CDFEE1", textColor: "#0C5132" },
};

const OrderStatusTooltip: React.FC<OrderStatusProps> = ({ orderStatusCode, orderStatusText }) => {
  const status = orderStatusMap[orderStatusCode] ? orderStatusMap[orderStatusCode] : { bgColor: "#f1f1f1", textColor: "black" };
  const order_statusText = orderStatusText || "Not status found";

  if (!status) {
    return null;
  }

  return (
    <div>
      <p
        className={`font-semibold flex items-center w-fit mt-2 text-[.8rem] h-6 px-2 rounded-full`}
        style={{
          backgroundColor: status?.bgColor || "#f1f1f1",
          color: status?.textColor || "black",
        }}
      >
        {order_statusText || "Pending"}
      </p>
    </div>
  );
};

export default OrderStatusTooltip;
