import { Combobox, Transition } from "@headlessui/react";
import React, { ChangeEvent, FC, useEffect, useState, useRef } from "react";

import SearchIcon from "src/assets/down-arrow.svg";
import { cn, getValueFromNestedObject } from "src/utils/utils";
import Checkbox from "./checkbox.component";

interface AutocompleteProps {
  placeholder: string;
  inputClassName?: string;
  dropdownClassName?: string;
  onSelectionChange: any;
  suggestionList: Array<any>;
  displayKey: string;
  uniqueKey?: string;
  disabled?: boolean;
  currentSelected?: Array<any>;
  defaultValue?: Array<any>;
  getAllSelectedValues?: (value: any[]) => void;
  controlledValue?: any[];
}

const MultiSelect: FC<AutocompleteProps> = ({
  placeholder,
  inputClassName,
  dropdownClassName,
  suggestionList,
  displayKey,
  uniqueKey,
  disabled,
  defaultValue,
  onSelectionChange,
  controlledValue,
}) => {
  const [selectedValues, setSelectedValues] = useState<any[]>(defaultValue || []);
  const [query, setQuery] = useState<string>("");

  const filteredQuery =
    query === ""
      ? suggestionList
      : suggestionList.filter(
          (item) =>
            getValueFromNestedObject(item, displayKey)
              ?.toString()
              ?.toLowerCase()
              ?.replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    if (controlledValue && Array.isArray(controlledValue)) {
      const transformedData = controlledValue?.map((item: any) => getValueFromNestedObject(item, displayKey));
      setSelectedValues(transformedData || []);
    }
  }, [controlledValue]);

  const selectHandler = (value: any) => {
    const displayValue = getValueFromNestedObject(value, displayKey);
    const isPresent = selectedValues.find((item) => item.toString().toLowerCase().trim() === displayValue.toString().toLowerCase().trim());

    if (isPresent) {
      const newList = selectedValues.filter((item) => item !== displayValue);
      onSelectionChange(value, "remove");
      setSelectedValues(newList);
    } else {
      setSelectedValues((prev) => [...prev, displayValue]);
      onSelectionChange(value, "add");
    }
    setQuery("");
  };

  const removeTag = (tag: string) => {
    const removedItem = suggestionList.find((item) => getValueFromNestedObject(item, displayKey)?.toString() === tag);

    if (removedItem) {
      const updatedValues = selectedValues.filter((item) => item !== tag);
      setSelectedValues(updatedValues);
      onSelectionChange(removedItem, "remove");
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Combobox value={selectedValues} disabled={disabled} nullable multiple>
      <div className="relative">
        <div
          onClick={() => inputRef.current?.focus()}
          className={`border w-full h-auto rounded-lg focus:bg-white focus:outline-none text-sm font-normal text-[#1E1E1E] px-3 py-2 flex flex-wrap items-center gap-2 ${inputClassName}`}
        >
          {selectedValues.map((tag, index) => (
            <div key={index} className="bg-gray-100 text-purple-700 px-0.5 py-0.25 rounded text-[10px] flex items-center gap-0.5">
              <span>{tag}</span>
              <button type="button" className="text-sm leading-none text-red-600" onClick={() => removeTag(tag)}>
                &times;
              </button>
            </div>
          ))}
          <Combobox.Input
            className="flex-grow bg-transparent focus:outline-none min-w-[6rem]"
            onChange={inputChangeHandler}
            placeholder={selectedValues.length ? "" : placeholder}
            autoComplete="off"
            value={query}
            ref={inputRef}
          />
        </div>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          <img src={SearchIcon} alt="search icon" />
        </Combobox.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options
            className={`bg-white border absolute mt-1 max-h-60 w-full overflow-y-auto rounded-md py-2 text-base shadow-lg focus:outline-none sm:text-sm z-10 ${dropdownClassName}`}
          >
            {query && !filteredQuery.length && <p className="ml-2">Not found...</p>}
            {filteredQuery?.slice(0, 20).map((item) => (
              <Combobox.Option key={uniqueKey ? item[uniqueKey] : item.id} value={getValueFromNestedObject(item, displayKey)}>
                {({ active, selected }) => (
                  <div
                    onClick={() => selectHandler(item)}
                    className={cn(
                      `${active ? "bg-purple-50 text-purple-700" : ""} px-2 py-3 cursor-pointer flex justify-between items-center gap-3 font-semibold`
                    )}
                  >
                    {getValueFromNestedObject(item, displayKey)}
                    <Checkbox checked={selected} onChange={() => {}} />
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default MultiSelect;
