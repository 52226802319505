interface MetricsCardProps {
  heading: string;
  title: string;
  subtitle: string;
}

const InvoiceCard = ({ title, subtitle, heading }: MetricsCardProps) => {
  return (
    <div className="flex flex-wrap w-[19rem] 2xl:w-[20rem] items-center p-5 border shadow-md rounded-xl">
      <div className="px-3 py-2 w-full">
        <p className="text-base font-semibold">{heading}</p>

        <h4 className="flex justify-between items-center mt-4 text-4xl">{title}</h4>
        <p className="mt-2 text-sm font-normal text-gray-500">{subtitle}</p>
      </div>
    </div>
  );
};

export default InvoiceCard;
