import { configureStore } from "@reduxjs/toolkit";
import { authSlice, AuthState } from "./auth-slice";
import { mastersSlice, MastersState } from "./master-slice";
import { companySlice, CompanyState } from "./company-slice";
import { productSlice, ProductState } from "./product-slice";
import { divisionSlice, DivisionState } from "./division-slice";
import { counterSlice, CounterState } from "./counter-slice";
import { employeeSlice, EmployeeState } from "./employee-slice";
import { versioningSlice, VersioningState } from "./versioning-slice";
import { bulkUploadActions, bulkUploadSlice, BulkUploadState } from "./bulk-upload-slice";
import floatingMenuSlice, { ProgressMenuState } from "./floating-menu-slice";
import { craSlice } from "src/features/fields/context/cra-slice";
import { praSlice } from "src/features/fields/context/pra-slice";
import { stockistSlice, StockistState } from "./stockist-slice";
import { accessControlSlice, AccessControlState } from "./access-control-slice";
import { counterBulkUploadActions, counterBulkUploadSlice, CounterBulkUploadState } from "./counter-bulk-upload-slice";
import { geographySlice, GeographyState } from "./geography-slice";
import { ordSlice, OrdState } from "./order-slice";

export interface RootState {
  auth: AuthState;
  master: MastersState;
  company: CompanyState;
  product: ProductState;
  division: DivisionState;
  stockist: StockistState;
  counter: CounterState;
  employee: EmployeeState;
  versioning: VersioningState;
  bulkUpload: BulkUploadState;
  floatingMenu: ProgressMenuState;
  geography: GeographyState;
  cra: any;
  pra: any;
  accessControl: AccessControlState;
  counterBulkUpload: CounterBulkUploadState;
  ord: OrdState;
}

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    master: mastersSlice.reducer,
    company: companySlice.reducer,
    product: productSlice.reducer,
    division: divisionSlice.reducer,
    counter: counterSlice.reducer,
    employee: employeeSlice.reducer,
    versioning: versioningSlice.reducer,
    stockist: stockistSlice.reducer,
    bulkUpload: bulkUploadSlice.reducer,
    floatingMenu: floatingMenuSlice.reducer,
    geography: geographySlice.reducer,
    cra: craSlice.reducer,
    pra: praSlice.reducer,
    accessControl: accessControlSlice.reducer,
    counterBulkUpload: counterBulkUploadSlice.reducer,
    ord: ordSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [bulkUploadActions.setUploadedFile.type, counterBulkUploadActions.setUploadedFile.type],
      },
    }),
});

export default store;
