import React, { ReactNode } from "react";
import { SubHeader } from "src/components";

interface CounterLayoutProps {
  children: ReactNode;
}

const InvoiceLayout: React.FC<CounterLayoutProps> = ({ children }) => {
  return (
    <div className="w-full">
      <div className="flex">
        <SubHeader />
      </div>

      {children}
    </div>
  );
};

export default InvoiceLayout;
