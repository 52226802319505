import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import OrdersHomeScreen from "../OrdersDetail/OrdersHomeScreen";
import OrderPendingScreen from "../OrdersDetail/OrdersPendingScreen";
import { useParams } from "react-router-dom";

const tabs = ["Counters", "Orders"];

const OrdersHomePage = () => {
  const { tabIndex } = useParams();
  return (
    <div>
      <section>
        <header className="flex px-[3.75rem] items-center justify-between py-5">
          <div>
            <h1 className="text-[#49484C] font-bold text-[2rem]">Orders</h1>
            <p className="font-medium text-base text-[#929194]">View and manage all your orders.</p>
          </div>
          {/* <Button className="text-white bg-[#586AF5]">Initiate Order</Button> */}
        </header>

        {/* table  */}
        <main>
          <Tab.Group defaultIndex={tabIndex ? parseInt(tabIndex) : 1}>
            <Tab.List className="border-b-[1px] shadow-md border-b-[rgba(0, 0, 0, 0.10)] mb-[1.16rem] px-[3.75rem] mt-6">
              {tabs.map((item: string) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }: { selected: boolean }) => (
                    <div
                      className={`relative inline-block cursor-pointer -bottom-[1px] px-4 py-3 font-semibold text-sm  border-b-2  not-italic  hover:border-b-[#586AF5] hover:text-[#586af5] focus:outline-none ${
                        selected ? "text-[#586af5] border-b-[#586AF5]" : "text-[rgba(0,0,0,0.4)] border-b-transparent"
                      }`}
                    >
                      {item}
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              <Tab.Panel className="px-[3.75rem]">
                <OrdersHomeScreen />
              </Tab.Panel>
              <Tab.Panel className="px-[3.75rem]">
                <OrderPendingScreen />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </main>
      </section>
    </div>
  );
};

export default OrdersHomePage;
