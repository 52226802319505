import { Tab } from "@headlessui/react";
import InvoiceCard from "../invoice-master/invoice-card";
import classNames from "classnames";
import AllInvoices from "./invoice-all.component";
import { useEffect, useState } from "react";
import { fetchEscalatedInvoicesData, fetchInvoicesData, fetchPriorityInvoicesData } from "src/services/invoice-api";

const categories = ["All invoices", "Priority tasks", "Escalated"];

const InvoiceEntry = () => {
  const [selectedTab, setSelectedTab] = useState(0); // Track the active tab
  const [invoicesData, setInvoicesData] = useState([]);
  const [tabDataCache, setTabDataCache] = useState<{ [key: number]: any }>({}); // Cache responses for tabs

  const fetchTabData = async (tabIndex: number) => {
    try {
      let response;

      if (tabDataCache[tabIndex]) {
        // Use cached data if available
        setInvoicesData(tabDataCache[tabIndex]);
        return;
      }

      switch (tabIndex) {
        case 0:
          response = await fetchInvoicesData();
          break;
        case 1:
          response = await fetchPriorityInvoicesData();
          break;
        case 2:
          response = await fetchEscalatedInvoicesData();
          break;
        default:
          return;
      }

      const data = response?.data?.data?.invoiceData || [];
      setInvoicesData(data);
      setTabDataCache((prev) => ({ ...prev, [tabIndex]: data })); // Cache the data
    } catch (error) {
      console.error("Error fetching invoices data:", error);
    }
  };

  useEffect(() => {
    // Fetch data for the default tab when the component loads
    fetchTabData(selectedTab);
  }, [selectedTab]);

  return (
    <div className="mx-auto">
      <section className="flex flex-wrap justify-between mx-auto mt-10">
        <InvoiceCard title={"4,204"} subtitle="New invoices this month" heading="Total invoices" />
        <InvoiceCard title={"21"} subtitle="Invoices this month" heading="Invoices escalated" />
        <InvoiceCard title={"4,024"} subtitle="New invoices this month" heading="MTD" />
        <InvoiceCard title={"4,204"} subtitle="New invoices this month" heading="Total invoices" />
      </section>
      <div className="px-2 py-16 w-full sm:px-0">
        <Tab.Group
          selectedIndex={selectedTab}
          onChange={(index) => setSelectedTab(index)} // Update the selected tab
        >
          <div className="flex justify-between">
            <Tab.List className="flex p-1 space-x-1 rounded-lg bg-[#e3e3e3] w-[500px] h-[38px] items-center">
              {categories.map((category, index) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-md py-1.5 text-sm font-normal leading-5",
                      "focus:outline-none",
                      selected ? "bg-white" : "hover:bg-white/[0.12] hover:text-black"
                    )
                  }
                >
                  {category}
                </Tab>
              ))}
            </Tab.List>
          </div>
          <Tab.Panels className="mt-2">
            {categories.map((_, index) => (
              <Tab.Panel
                key={index}
                className={classNames("w-full bg-white rounded-xl", "ring-offset-2 ring-white/60 focus:outline-none focus:ring-2")}
              >
                <AllInvoices allInvoiceData={index === selectedTab ? invoicesData : []} />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default InvoiceEntry;
