export const getOrderDetailsCardInfo = (selectedCounter: any) => {
  let response: any = {};

  response = {
    "Doctor Full Name": selectedCounter?.counter_location?.counter_hdr_id?.counter_name || "-",
    "Counter Type": selectedCounter?.drph_lnk_type?.dr_ph_lnk_code || "-",

    "Mobile Number": selectedCounter?.mobile || "-",
    "MCI Regn. No.": selectedCounter?.counter_location?.counter_hdr_id?.counter_name || "-",

    "Clinic Name": selectedCounter?.counter_location?.clinic_name || "-",
    "Email ID": selectedCounter?.counter_location?.counter_hdr_id?.email || "-",

    City: selectedCounter?.counter_location?.address || "-",
    Location: selectedCounter?.counter_location?.address || "-",

    Speciality: selectedCounter?.counter_location?.counter_hdr_id?.speciality_id?.speciality_desc || "-",
    // "Pin Code": selectedCounter?.counter_location?.pincode || "-",
  };

  return response;
};
